import { createContext, useContext } from 'react';

import { OutfitSelectedProduct } from './ProductTypes';

export interface OutfitContextType {
    onProductClick: (product: OutfitSelectedProduct) => void;
    selectedProduct: OutfitSelectedProduct | null;
}

const OutfitContext = createContext<OutfitContextType | null>(null);

export const useOutfitContext = () => useContext(OutfitContext);

export default OutfitContext;
