import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';

interface TextFieldProps {
    label: string;
    name: string;
    placeholder: string;
}

const TextField: FC<TextFieldProps> = ({ label, name, placeholder }) => (
    <Field name={name}>
        {({ field, form }: FieldProps) => (
            <FormControl isInvalid={!!form.errors[name] && !!form.touched[name]}>
                <FormLabel fontWeight="semibold" htmlFor={name}>{label}</FormLabel>
                <Input
                    id={name}
                    {...field}
                    placeholder={placeholder}
                    type='text'
                    variant='outline'
                />
                <FormErrorMessage>{form.errors[name] as string}</FormErrorMessage>
            </FormControl>
        )}
    </Field>
);

export default TextField;
