import { Button, HStack, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getClient, getExperience } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { IframeLookData } from '../types/api-types';
import { COMMON_LOCALES } from '../utils/constants';
import { baseUrl } from '../utils/queries-helpers';

const Outfit = () => {
    const { t } = useTranslation([COMMON_LOCALES]);
    const [searchParams, setSearchParams] = useSearchParams();

    const experienceIframeUrl = useAppSelector((state) => state.session.config?.experience_iframe_url);
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExprience = useAppSelector((state) => getExperience(state));

    const [look, setLook] = useState<IframeLookData>();

    // ---- Get init parameters from searchParams in memo so it doesn't trigger rerendering when searchParams is changed ----
    const initLookId = useMemo(() => searchParams.get('look_id'), []);
    const initGarmentId = useMemo(() => searchParams.get('garment_id'), []);
    const initModelId = useMemo(() => searchParams.get('model_id'), []);

    // ---- Download Look Image ----
    const handleDownload = () => {
        if (!look || !look.lookId || !currentClient) {
            return;
        }

        // ---- Build image URL ----
        const imageUrl = `${baseUrl().replace('/api', '/_media')}/look/${currentClient.id}?${
            selectedExprience ? `experience_id=${selectedExprience.id}&` : ''}&look_id=${
            encodeURIComponent(look.lookId)}&look_width=1200&download=Veesual_Outfit.png`;

        // ---- Open in a new tab ----
        window.open(imageUrl, '_blank');
    };

    // ---- Plug/Unplug the message listener to get infos from iframe ----
    useEffect(() => {
        const handleIframeResponse = (event: MessageEvent) => {
            try {
                if (typeof event.data !== 'string') {
                    return;
                }

                // ---- The string we want to handle is formated like this: TYPE:DATA ----
                const splitted = event.data.split(':');

                // ---- Check if it's a message we want to handle (the one sending the look) and save it in the state ----
                if (splitted[0] === 'veesual_look_update') {
                    const newLook: IframeLookData = JSON.parse(splitted.slice(1).join(':'));
                    setLook(newLook);

                    if (newLook.lookId) {
                        // ---- Add new look id in searchparams ----
                        searchParams.set('look_id', newLook.lookId);

                        // ---- Remove garment and model ID from searchParams as we don't need them anymore ----
                        searchParams.delete('garment_id');
                        searchParams.delete('model_id');

                        setSearchParams(searchParams);
                    }
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('sendIframeMessageWithResponse exception');
            }
        };

        // ---- Plug listener ----
        window.addEventListener('message', handleIframeResponse);

        // ---- Clean up ----
        return () => {
            window.removeEventListener('message', handleIframeResponse);
        };
    }, []);

    return (
        <HStack boxSize="full" id="outfitPageContainerId" maxH="full">
            {
                experienceIframeUrl && <iframe
                    height="100%"
                    src={`${experienceIframeUrl}${
                        initLookId && !initGarmentId && !initModelId ? `&look_id=${encodeURIComponent(initLookId)}` : ''
                    }${
                        initModelId ? `&model_id=${initModelId}` : ''
                    }${
                        initGarmentId ? `&garment_id=${initGarmentId}` : ''
                    }`}
                    style={{ paddingBottom: 8 }}
                    width="80%"
                />
            }
            <VStack h="100%" w="20%">
                <Button onClick={handleDownload}>
                    {t('download_look')}
                </Button>
            </VStack>
        </HStack>
    );
};

export default Outfit;
