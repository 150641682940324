import { Button, Icon, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface NavigationButtonProps {
    current: boolean,
    label: string;
    MenuIcon: typeof Icon;
    onClick: () => void;
}

const NavigationButton: FC<NavigationButtonProps> = ({ current, label, MenuIcon, onClick }) => (
    <Button boxSize={20} disabled={current} minH={20} onClick={onClick} variant="navigation">
        <VStack boxSize="full" justify="center">
            <MenuIcon boxSize={5} />
            <Text>{label}</Text>
        </VStack>
    </Button>
);

export default NavigationButton;
