import './i18n/config';
import './theme/style.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';

import { ChakraProvider, Spinner, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Provider } from 'react-redux';

import { fakeData, FakeDataContext, FakeDataType } from './fakeData';
import Router from './Router';
import { store } from './services/store/store';
import theme from './theme';

// More details on available options here:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
Sentry.init({
    dsn: 'https://d70ec2f46a10dd737c0a3f8b02f0364d@o4508262871793664.ingest.de.sentry.io/4508263610646608',
    environment: process.env.NODE_ENV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Session Replay
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // Tracing
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/.*\.veesual\.ai\/.*/],
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
});

const App = () => (
    <ChakraProvider theme={theme}>
        <React.Suspense fallback={<VStack height='100vh' justifyContent='center' ><Spinner size='xl'/></VStack>}>
            <Provider store={store}>
                <FakeDataContext.Provider value={fakeData as FakeDataType}>
                    <Router />
                </FakeDataContext.Provider>
            </Provider>
        </React.Suspense>

    </ChakraProvider>
);

export default App;
