/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Home = (props: IconProps) => (
    <Icon {...props}>
        <path d="M13 18h-2v-2h2v2Zm2-4H9v6h6v-6Zm4-4.7V4h-3v2.6L12 3 2 12h3l7-6.31L19 12h3l-3-2.7Z" fill="currentColor"/>
    </Icon>
);

export default Home;
