import { Box, Image, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import { Pose } from '../../assets/icons';
import { getConfig } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Garment } from '../../types/api-types';
import preventCmdCtrlClick from '../../utils/click-helpers';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import resizeImage from '../../utils/image';
import { getCleanSearch } from '../../utils/url-helpers';

interface LookGarmentCardProps {
    garment: Garment,
    mainImageType?: 'garment' | 'look'
}

export default function LookGarmentCard(props: LookGarmentCardProps) {
    const { garment, mainImageType } = props;

    const navigate = useCustomNavigate();
    const { search } = useLocation();

    const config = useAppSelector((state) => getConfig(state));

    const [onMouseOver, setOnMouseOver] = useState<boolean>(false);

    const garmentImgSrc = useMemo(() => resizeImage(garment.image_url, { width: 800 }), [garment]);

    const lookImgSrc = useMemo(() => garment.look?.look_image_url || <Pose h="100%" w="100%"/>, [garment]);

    // ---- Main image of the card that depends on the mainImageType props and the hover state ----
    const mainImageSrc = useMemo(() => {
        if (mainImageType) {
            switch (mainImageType) {
                case 'garment':
                    if (typeof lookImgSrc !== 'string') {
                        return garmentImgSrc;
                    }

                    return onMouseOver ? lookImgSrc : garmentImgSrc;
                case 'look':
                    return onMouseOver ? garmentImgSrc : lookImgSrc;
            }
        }

        return onMouseOver ? lookImgSrc : garmentImgSrc;
    }, [mainImageType, onMouseOver, garmentImgSrc, lookImgSrc]);

    // ---- Thumbnail image doesn't exist if we hover and is the oposite of the mainImage ----
    const thumbnailImageSrc = useMemo(() => {
        if (onMouseOver) {
            return null;
        }

        if (mainImageSrc === garmentImgSrc) {
            return lookImgSrc;
        }

        return garmentImgSrc;
    }, [onMouseOver, mainImageSrc]);

    return (
        <Box
            as="a"
            h={0}
            href={`/looks/${garment.garment_id}?${getCleanSearch(search)}`}
            onClick={(e: React.MouseEvent<HTMLElement>) => preventCmdCtrlClick(e, () => navigate(`/looks/${garment.garment_id}`))}
            onMouseEnter={() => setOnMouseOver(true)}
            onMouseLeave={() => setOnMouseOver(false)}
            overflow="hidden"
            paddingBottom={
                config
                    ? `${(100 / (mainImageType === 'look' ? config.look_image_ratio : config.garment_image_ratio)).toString()}%`
                    : '120%'
            }
            position="relative"
            w="100%"
        >
            {
                thumbnailImageSrc
                && <>
                    {
                        typeof thumbnailImageSrc !== 'string'
                            ? <VStack
                                backgroundColor="white"
                                h="20%"
                                position="absolute"
                                right={4}
                                top={4}
                                w="20%"
                                zIndex={1}
                            >
                                {thumbnailImageSrc}
                            </VStack>
                            : <Image
                                h="auto"
                                objectFit={'contain'}
                                position="absolute"
                                right={4}
                                src={thumbnailImageSrc}
                                top={4}
                                w="30%"
                                zIndex={1}
                            />
                    }
                </>
            }
            {
                typeof mainImageSrc !== 'string'
                    ? <VStack
                        cursor="pointer"
                        h="100px"
                        left='calc(50% - 50px)'
                        position="absolute"
                        top='calc(50% - 50px)'
                        w="100px"
                    >
                        {mainImageSrc}
                    </VStack>
                    : <LazyLoad height="100%" offset={100} once overflow>
                        <Image
                            border="2px solid"
                            borderColor="transparent"
                            borderRadius="lg"
                            cursor="pointer"
                            height="100%"
                            objectFit={'contain'}
                            position="absolute"
                            src={mainImageSrc}
                            width="100%"
                        />
                    </LazyLoad>
            }

        </Box>
    );
}
