import { Checkbox, VStack } from '@chakra-ui/react';
import { FC, useCallback } from 'react';

import { FacetTerms } from '../../types/api-types';
import Collapse from '../Collapse';
import { useFiltersContext } from './FiltersContext';

interface MultiSelectFilterProps {
    facet: FacetTerms,
    keyName: string,
}

const MultiSelectFilter: FC<MultiSelectFilterProps> = ({ facet, keyName }) => {
    const FilterContext = useFiltersContext();
    const isChecked = useCallback(
        (choiceValue: string) => {
            let selectFilter = null;

            if (FilterContext?.filters) {
                selectFilter = FilterContext?.filters.find((filter) => filter.filterKey === keyName && filter.filterValue === choiceValue);
            }

            return !!selectFilter;
        },
        [FilterContext],
    );

    return (
        <Collapse label={facet.label || keyName}>
            <VStack align="flex-start">
                {facet.data.map((choice) => (
                    <Checkbox isChecked={isChecked(choice.value)} key={choice.value} onChange={(e) => {
                        if (e.target.checked === true) {
                            FilterContext?.selectAFilter({ filterKey: keyName, filterLabel: choice.label, filterValue: choice.value });
                        } else {
                            FilterContext?.deselectAFilter({ filterKey: keyName, filterValue: choice.value });
                        }
                    }}>
                        {`${choice.label || choice.value}${choice.count ? ` (${choice.count})` : ''}`}
                    </Checkbox>
                ))}
            </VStack>
        </Collapse>
    );
};

export default MultiSelectFilter;
