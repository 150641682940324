import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { FC, useState } from 'react';

import { ViewIcon, ViewOffIcon } from '../../assets/icons';

interface PasswordFieldProps {
    label: string;
    name: string;
    placeholder: string;
}

const PasswordField: FC<PasswordFieldProps> = ({ label, name, placeholder }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!form.errors[name] && !!form.touched[name]}>
                    <FormLabel fontWeight="semibold" htmlFor={name}>{label}</FormLabel>
                    <InputGroup>
                        <Input
                            id={name}
                            {...field}
                            placeholder={placeholder}
                            type={showPassword ? 'text' : 'password'}
                            variant='outline'
                        />
                        <InputRightElement>
                            <Button
                                _active={{ bg: 'transparent' }}
                                _hover={{ bg: 'transparent' }}
                                onClick={() => setShowPassword(!showPassword)}
                                variant="ghost"
                            >
                                {showPassword
                                    ? <ViewOffIcon boxSize={5} color='gray.400' />
                                    : <ViewIcon boxSize={5} color='gray.400' />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors[name] as string}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
};

export default PasswordField;
