import qs from 'qs';

export const getObjectUrl = (urlParams: string) => qs.parse(urlParams);

export const getCleanSearch = (search: string) => {
    const parsedUrl = getObjectUrl(search);

    return qs.stringify({
        clientId: parsedUrl.clientId || parsedUrl['?clientId'],
        experienceId: parsedUrl.experienceId || parsedUrl['?experienceId'],
    });
};
