/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Pose = (props: IconProps) => (
    <Icon viewBox="0 0 38 38" {...props}>
        <path d="M38 29.583c0 2.507-2.154 4.742-6.064 6.294-6.826 2.83-19.022 2.83-25.848 0-5.756-2.217-8.454-6.732-3.493-10.693 1.42-1.078 3.363-1.975 5.774-2.666a1.486 1.486 0 0 1 .816 2.857c-4.279 1.227-6.194 2.997-6.194 4.208 0 1.152 1.606 2.505 4.19 3.53 3.137 1.245 7.338 1.931 11.831 1.931 4.493 0 8.695-.686 11.831-1.93 2.585-1.026 4.19-2.379 4.19-3.531 0-1.21-1.915-2.981-6.193-4.207a1.486 1.486 0 0 1 .816-2.858c2.41.69 4.353 1.588 5.773 2.666 2.125 1.612 2.571 3.282 2.571 4.4Zm-25.96-7.318v-4.83c-.071-3.545 3.042-6.61 6.575-6.538h.943c3.532-.072 6.646 2.993 6.575 6.538v4.83c0 .464-.2.89-.57 1.17-.136.11-2.102 1.757-2.102 4.55 0 1.762-1.514 3.195-3.375 3.195h-2c-1.86 0-3.374-1.433-3.374-3.194 0-2.793-1.966-4.44-2.102-4.55-.37-.282-.57-.707-.57-1.171Zm2.967-.65c.934.947 2.672 3.144 2.672 6.37 0 .087.187.224.408.224h1.999c.221 0 .408-.137.408-.223 0-3.227 1.738-5.424 2.672-6.37v-4.18c0-1.967-1.619-3.567-3.608-3.567h-.943c-1.99 0-3.608 1.6-3.608 3.566v4.18Zm4.005-11.981a4.83 4.83 0 0 1-4.821-4.83c.265-6.406 9.379-6.405 9.642 0a4.83 4.83 0 0 1-4.82 4.83Zm0-6.687a1.858 1.858 0 0 0-1.854 1.857c.102 2.465 3.607 2.464 3.708 0a1.858 1.858 0 0 0-1.854-1.857Z" fill="currentColor"/>
    </Icon>
);

export default Pose;
