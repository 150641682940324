import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface CustomModalProps {
    cancelButtonLabel: string;
    confirmButtonLabel: string;
    isOpen: boolean,
    onCancel: () => void;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    children?: ReactNode
}

const CustomModal: FC<CustomModalProps> = ({ cancelButtonLabel, confirmButtonLabel, isOpen, children, onCancel, onClose, onConfirm, title }) => {
    const cancelButtonAction = () => {
        onClose();
        onCancel();
    };
    const confirmButtonAction = () => {
        onClose();
        onConfirm();
    };

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                    <Button mr={3} onClick={cancelButtonAction} variant='ghost'>
                        {cancelButtonLabel}
                    </Button>
                    <Button onClick={confirmButtonAction} variant='solid'>{confirmButtonLabel}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CustomModal;
