/**
 * Load the local data from a given key
 * @param localKey string for the key we want to load
 * @returns the data if success, null otherwise
 */
export const loadFromLocalStorage = (localKey: string) => {
    try {
        const rawData = localStorage.getItem(localKey);
        if (rawData !== null) {
            return JSON.parse(rawData);
        }

        return null;
    } catch (e) {
        return null;
    }
};

/**
 * Store data in a given key in the local storage
 * @param localKey string for the key in the localStorage
 * @param data string of the data we want to store
 * @returns 0 if success -1 otherwise
 */
export const storeInLocalStorage = (localKey: string, data: string) => {
    try {
        localStorage.setItem(localKey, data);

        return 0;
    } catch (e) {
        return -1;
    }
};
