import {
    Center,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderTrack,
    Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { FacetMinMax } from '../../types/api-types';
import Collapse from '../Collapse';
import { useFiltersContext } from './FiltersContext';

interface RangeSelectFilterProps {
    facet: FacetMinMax,
    keyName: string,
    minStepsBetweenThumbs?: number,
    step?: number,
}

const RangeSelectFilter = React.forwardRef<{resetValues() : void}, RangeSelectFilterProps>(
    ({ facet, keyName, minStepsBetweenThumbs = 5, step = 1 }, ref) => {
        const [minValue, setMinValue] = useState<number>(facet.data.min);
        const [maxValue, setMaxValue] = useState<number>(facet.data.max);

        const [fixedMin, setFixedMin] = useState<number | null>(null);
        const [fixedMax, setFixedMax] = useState<number | null>(null);

        React.useImperativeHandle(
            ref,
            () => ({
                resetValues() {
                    setMinValue(facet.data.min);
                    setMaxValue(facet.data.max);
                },
            }),
        );

        const FilterContext = useFiltersContext();

        const onValuesChangeEnd = ([newMin, newMax]: [number, number]) => {
            if (FilterContext) {
                if (newMin === fixedMin && newMax === fixedMax) {
                    return FilterContext.deselectAFilter({ filterKey: keyName, filterValue: '' });
                }

                return FilterContext.selectAFilter({ filterKey: keyName, filterLabel: `${newMin}-${newMax}`, filterValue: `${newMin}:${newMax}` });
            }

            return null;
        };

        const onValuesChange = ([newMin, newMax]: [number, number]) => {
            setMinValue(newMin);
            setMaxValue(newMax);
        };

        useEffect(() => {
            if (!fixedMin && !fixedMax) {
                setFixedMin(facet.data.min);
                setFixedMax(facet.data.max);
            }
        }, [facet]);

        return (
            <Collapse label={`${facet.label} ${facet.unit ? `(${facet.unit})` : ''}` || keyName} >
                <Center>
                    {
                        (fixedMin && fixedMax)
                    && <RangeSlider
                        aria-label={['min', 'max']}
                        max={fixedMax}
                        mb={5}
                        min={fixedMin}
                        minStepsBetweenThumbs={minStepsBetweenThumbs} // RangeSliderThumb size
                        mr={3.5}
                        onChange={onValuesChange}
                        onChangeEnd={onValuesChangeEnd}
                        step={step}
                        value={[minValue, maxValue]}
                        w="85%"
                    >
                        <RangeSliderTrack h={0.5}>
                            <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb index={0}>
                            <Text color="gray.700" fontSize="sm" mt={10}>{minValue}</Text>
                        </RangeSliderThumb>
                        <RangeSliderThumb index={1}>
                            <Text color="gray.700" fontSize="sm" mt={10}>{maxValue}</Text>
                        </RangeSliderThumb>
                    </RangeSlider>
                    }

                </Center>
            </Collapse>
        );
    });

export default RangeSelectFilter;
