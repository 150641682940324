import React from 'react';
import { useDispatch } from 'react-redux';

import { Filters } from '../components/filters/filtersTypes';
import ProductsLayout from '../components/products/ProductsLayout';
import { invalidateGetModelsAction, useGetModelsQuery } from '../services/api/api-model';
import { getClient, getExperience } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { GarmentQueryInput } from '../types/api-types';
import { PRODUCTS_CATEGORIES } from '../utils/constants';

let shouldRefresh = false;

const Model = () => {
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const [queryInputObject, setQueryInputObject] = React.useState<GarmentQueryInput>(
        {
            clientId: currentClient ? currentClient.id : '',
            collapse: 1,
            experienceId: selectedExperience ? selectedExperience.id : undefined,
        },
    );
    const { data: modelResponse, isFetching: isLoadingModels } = useGetModelsQuery(queryInputObject);
    const dispatch = useDispatch();

    function useModelQuery(
        filters: Filters, query = '', page = 1, collapse: number | null = null, forceRefetch = false,
    ) {
        if (currentClient) {
            setQueryInputObject({ clientId: currentClient.id, collapse, experienceId: selectedExperience?.id, filters, page, query });
        }
        if (forceRefetch) {
            shouldRefresh = true;
        }
    }

    React.useEffect(() => {
        if (shouldRefresh) {
            dispatch(invalidateGetModelsAction);
        }
        shouldRefresh = false;
    }, [queryInputObject]);

    return (
        <ProductsLayout
            category={PRODUCTS_CATEGORIES.MODEL}
            clientId={currentClient?.id || ''}
            dataQuery={useModelQuery}
            isLoading={isLoadingModels}
            productTypes={[]}
            queryResponse={modelResponse}
        />
    );
};

export default Model;
