import { Button, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useLoginMutation } from '../../services/api/api-auth';
import { invalidateGetUserAction, useChangeEmailMutation, useGetUserQuery } from '../../services/api/api-user';
import { ErrorMutation } from '../../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, PROFILE_LOCALES } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import { handleLogin, handleLogout } from '../../utils/login-helpers';
import { EmailField, PasswordField } from '../fields';
import UnclosableBanner from '../UnclosableBanner';

const Email = () => {
    const { t } = useTranslation([PROFILE_LOCALES, ERROR_LOCALES]);
    const navigate = useCustomNavigate();
    const { data: user } = useGetUserQuery();
    const [changeMail, { error, isSuccess: isSuccessChange }] = useChangeEmailMutation();
    const [login, { isSuccess: isSuccessLogin }] = useLoginMutation();
    const dispatch = useDispatch();

    async function handleSubmit(values: {newEmail: string, password: string}) {
        if (user && user?.email !== '') {
            const resultChange = await changeMail({ newEmail: values.newEmail, oldEmail: user.email, password: values.password });

            // Error handling of the changeMail methode is done in the JSX
            if (!('error' in resultChange)) {
                try {
                    const loginResult = await login({ password: values.password, username: values.newEmail }).unwrap();
                    handleLogin(dispatch, loginResult);
                    dispatch(invalidateGetUserAction);
                } catch (errLogin) {
                    // ERROR LOGIN REQUEST
                    handleLogout(dispatch);
                    navigate('/login');
                }
            }
        }
    }

    const EmailSchema = Yup.object().shape({
        newEmail: Yup.string()
            .email(t('email.invalid', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
        password: Yup.string()
            .min(8, t('password.too_short', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
    });

    return (
        <VStack align='start' bg="white" borderRadius="2xl" maxW="590px" p={8} spacing={8} sx={{ '&>form': { w: 'full' } }} w="full">
            {
                error
                && <UnclosableBanner
                    description={(error as ErrorMutation).data.message}
                    title={t('submit', { ns: ERROR_LOCALES })}
                    type='error'
                />
            }
            {
                (isSuccessLogin && isSuccessChange)
                && <UnclosableBanner description={t('email.success')} title={t('success', { ns: COMMON_LOCALES })} type='success'/>
            }
            <Formik
                enableReinitialize
                initialValues={{
                    email: user?.email,
                    newEmail: '',
                    password: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={EmailSchema}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <VStack align='start' spacing={10} w="full">
                            <VStack align="flex-end" spacing={6} w="full">
                                <EmailField isReadOnly label={t('email.current_email.label')} name="email" />
                                <EmailField label={t('email.new_email.label')} name="newEmail" placeholder={t('email.new_email.placeholder')} />
                                <PasswordField
                                    label={t('email.password.label')}
                                    name="password"
                                    placeholder={t('email.password.placeholder')}
                                />
                            </VStack>
                            <Button isLoading={isSubmitting} type="submit" variant="solid">{t('email.submit_label')}</Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </VStack>
    );
};

export default Email;
