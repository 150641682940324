/* eslint-disable max-len */
import { Image, ImageProps } from '@chakra-ui/react';

const VeesualSquare = (props: ImageProps) => (
    <Image
        {...props}
        src={'./assets/illustrations/logo_Veesual-03.svg'}
    />
);

export default VeesualSquare;
