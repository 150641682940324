import { CloseIcon } from '@chakra-ui/icons';
import { Heading, HStack, IconButton, Image, ScaleFade, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface AnnouncementBannerProps {
    description: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
}

const AnnouncementBanner: FC<AnnouncementBannerProps> = ({ description, isOpen, onClose, title }) => (
    <ScaleFade in={isOpen} transition={{ enter: { duration: 0 }, exit: { duration: 0.4 } }} unmountOnExit>
        <HStack bg="white" borderRadius="lg" justify="space-between" px={8} py={4}>
            <HStack>
                <Image boxSize={8} mr={8} src="/assets/illustrations/tada.svg" />
                <VStack align="flex-start">
                    <Heading fontSize="md">{title}</Heading>
                    <Text fontSize="sm">{description}</Text>
                </VStack>
            </HStack>
            <IconButton
                aria-label='Close announcement'
                icon={<CloseIcon />}
                onClick={onClose}
                variant="ghost"
            />
        </HStack>
    </ScaleFade>
);

export default AnnouncementBanner;
