import { ArrowDownIcon, ArrowUpIcon, ChatIcon, ChevronDownIcon, RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Box,
    Container,
    Flex,
    Heading,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { OnChangeValue } from 'react-select';

import { Dashboard, Garment, Home, User, VeesualSquare } from '../../assets/icons';
import {
    useGetClientConfigQuery,
    useGetClientExperiencesQuery,
    useGetClientsQuery,
    useGetGroupClientQuery,
    useGetGroupConfigQuery,
    useGetGroupExperiencesQuery,
} from '../../services/api/api-client';
import { useGetUserQuery } from '../../services/api/api-user';
import {
    getClient,
    getConfig,
    getExperience,
    getGroup,
    getGroupConfig,
    isClientInitialized,
    setClient,
    setConfig,
    setExperience,
    setGroup,
    setGroupConfig,
} from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ClientResponse, CustomGroup, ErrorMutation, Experience } from '../../types/api-types';
import useConfigValidation from '../../utils/config-validation-hook';
import { ERROR_LOCALES, LAYOUT_LOCALES } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import { supportMailTo } from '../../utils/help-helpers';
import { handleLogout } from '../../utils/login-helpers';
import useVerticalScroll from '../../utils/vertical-scroll-hook';
import CustomDropdown from '../CustomDropdown';
import NavigationButton from './NavigationButton';

let configNotValid = true;

const AuthLayout = () => {
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useCustomNavigate();
    const { t } = useTranslation(LAYOUT_LOCALES);
    const { data: user, isSuccess: isUserSuccess, error: errorUser } = useGetUserQuery();
    const currentGroup = useAppSelector((state) => getGroup(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const configSlice = useAppSelector((state) => getConfig(state));
    const configHookValue = useConfigValidation();

    // Clients are used to restrict routes if the list is empty or null
    const { data: clients, error: errorClient } = useGetClientsQuery();

    const currentClient = useAppSelector((state) => getClient(state));
    const isClientInit = useAppSelector((state) => isClientInitialized(state));
    const dispatch = useDispatch();

    const groupConfigSlice = useAppSelector((state) => getGroupConfig(state));

    const { data: groupConfig, error: errorGroupConfig } = useGetGroupConfigQuery({
        experienceId: selectedExperience ? selectedExperience.id : undefined,
        groupId: currentGroup?.id || '',
    }, { skip: currentGroup?.groupType !== 'group' || !selectedExperience });

    const { data: groupExperiences } = useGetGroupExperiencesQuery(currentGroup?.id || '',
        { refetchOnMountOrArgChange: true, skip: currentGroup?.groupType !== 'group' });

    const { data: groupExperienceClient } = useGetGroupClientQuery({
        experienceId: selectedExperience ? selectedExperience.id : undefined,
        groupId: currentGroup?.id || '',
    }, { skip: currentGroup?.groupType !== 'group' });

    const { data: config, error: errorConfig } = useGetClientConfigQuery({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
    }, { skip: !isClientInit || !currentClient || currentClient?.internal === '' });

    const { data: experiences } = useGetClientExperiencesQuery(
        currentClient ? currentClient.id : '',
        { refetchOnMountOrArgChange: true, skip: !isClientInit || !currentClient || currentClient?.internal === '' },
    );

    const isCurrentPage = (path: string): boolean => path === pathname;
    const [pageName, setPageName] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configError, setConfigError] = useState<string>();

    // Custom object to handle clients with no group
    const [customGroups, setCustomGroups] = useState<CustomGroup[]>();

    const [leftMenuElement, setLeftMenuElement] = useState<HTMLDivElement | null>(null);

    const { isScrollableBottom, isScrollableTop, scrollTop, scrollBottom, manualUpdateContainerValues } = useVerticalScroll(leftMenuElement);

    const leftMenuWidth = '87px';
    const arrowTopOffset = '72px';
    const topMenuHeight = '64px';

    const isAll = currentGroup?.groupType === 'group' && currentClient?.internal === '';

    configNotValid = (currentGroup?.groupType === 'group' && !selectedExperience)
        || configHookValue;

    // ---- Main useEffect ----
    useEffect(() => {
        // ---- We get the current Search Parameters ----
        const clientId = searchParams.get('clientId');
        const groupId = searchParams.get('groupId');
        const experienceId = searchParams.get('experienceId');

        // ---- If there is an error on a request we set the error message to stop the app ----
        if (errorConfig) {
            setConfigError((errorConfig as ErrorMutation)?.data.message);

            return undefined;
        }
        if (errorClient) {
            setConfigError((errorClient as ErrorMutation)?.data.message);

            return undefined;
        }
        if (errorUser) {
            setConfigError((errorUser as ErrorMutation)?.data.message);

            return undefined;
        }
        if (errorGroupConfig) {
            setConfigError((errorGroupConfig as ErrorMutation)?.data.message);

            return undefined;
        }

        // ---- Default Loading ----
        setIsLoading(true);

        // ---- The clients are the root of data we need ----
        if (!clients) {
            return undefined;
        }

        // ---- Group Creation if they are not yet created ----
        if (!customGroups) {
            const groups: CustomGroup[] = [];

            // ---- Creation of groups array ----
            clients.forEach((client) => {
                const foundIndex = groups.findIndex((localGroup) => localGroup.id && client.group && localGroup.id === client.group.id);
                if (foundIndex === -1) {
                    const groupType = client.group ? 'group' : 'client';
                    groups.push({ clients: [client], groupType, id: client.group?.id || client.id, name: client.group?.name || client.name });
                } else {
                    groups[foundIndex].clients.push(client);
                }
            });
            setCustomGroups(groups);

            return undefined;
        }

        // ---- Group ID Handling ----
        if (groupId) {
            // ---- The groupId has priority over clientId ----
            if (clientId) {
                searchParams.delete('clientId');
                setSearchParams(searchParams);

                return undefined;
            }

            // ---- Set the correct group according to the groupId ----
            if (groupId !== currentGroup?.id) {
                dispatch(setGroup(customGroups.find((localGroup) => localGroup.id === groupId) || customGroups[0]));

                return undefined;
            }

            // ---- If a groupId is present it means we are on "all" mode ----
            if (currentClient?.id !== '') {
                dispatch(setClient({ id: '', internal: '', name: t('all') }));
                dispatch(setConfig(null));

                return undefined;
            }

            // ---- If we are already on the all client we check if we are allowed to ----
            if (groupConfig?.enable_all === false && groupExperienceClient) {
                searchParams.delete('groupId');
                searchParams.set('clientId', groupExperienceClient[0].id);
                setSearchParams(searchParams);

                return undefined;
            }
        }

        // ---- Client ID Handling ----
        if (clientId) {
            // ---- When the clientId doesn't match the currentClient we set the right data----
            if (clientId !== currentClient?.id) {
                const newClient = clients.find((client) => client.id === clientId) || null;
                if (!newClient) {
                    return undefined;
                }

                dispatch(setClient(newClient));

                // ---- Use to prevent wrong config when skip ----
                let newGroupConfig = groupConfig || null;

                // ---- Find the correct group according to new client (fake or real group) ----
                if (newClient.group) {
                    dispatch(setGroup(customGroups.find((localGroup) => localGroup.id === newClient.group?.id) || null));
                } else {
                    dispatch(setGroup(customGroups.find((localGroup) => localGroup.id === newClient.id) || null));
                    newGroupConfig = null;
                }

                // ---- Prevents wrong config when we skip the config request or because it's already cached ----
                dispatch(setConfig(config || null));
                dispatch(setGroupConfig(newGroupConfig));

                return undefined;
            }

            // ---- We set a default client if we don't have a client AND groupId ----
        } else if (!groupId) {
            searchParams.set('clientId', clients[0].id);
            setSearchParams(searchParams);

            return undefined;
        }

        // ---- Variable used to directly check the correct experience array according to groupId ----
        const activeExperiences = groupId ? groupExperiences : experiences;

        // ---- We do nothing if there are no experiences ----
        if (activeExperiences === undefined) {
            return undefined;
        }

        // ---- Experience ID Handling ----
        if (experienceId) {
            // ---- When the experienceId doesn't match the selected experience we set the correct one ----
            if (experienceId !== selectedExperience?.id) {
                const foundExperience = activeExperiences.find((localExp) => localExp.id === experienceId);
                dispatch(setExperience(foundExperience || null));

                // ---- Remove experienceID if we did not find an experience matching the url param ----
                if (!foundExperience) {
                    searchParams.delete('experienceId');
                    setSearchParams(searchParams);
                }

                return undefined;
            }

            // ---- We set a default experience when we have a matching experience group id or client id ----
        } else if (activeExperiences.length > 0) {
            // ---- If we are in group mode and we have the right default experience ----
            if (groupId && activeExperiences[0].group?.id === groupId) {
                searchParams.set('experienceId', activeExperiences[0].id);
                setSearchParams(searchParams);
            }

            // ----  If we have the currentClient in the default experience ----
            if (activeExperiences[0].clients?.find((localClient) => localClient.id === currentClient?.id)) {
                searchParams.set('experienceId', activeExperiences[0].id);
                setSearchParams(searchParams);

                // ---- Else, the currenctClient isn't included in the default experience ----
            } else {
                // ---- We look in all activeExperiences for one including the currentClient ----
                const foundExperience = activeExperiences.find(
                    (localExperience) => localExperience.clients.find((localClient) => localClient.id === currentClient?.id),
                );

                if (foundExperience) {
                    searchParams.set('experienceId', foundExperience.id);
                    setSearchParams(searchParams);
                }
            }

            return undefined;
        }

        // ---- Check if all data is correct to stop the loading ----
        if (
            (
                // ---- Check for group mode ----
                (currentGroup?.groupType === 'group' && groupConfig?.group_id === currentGroup?.id && experienceId === selectedExperience?.id)
                // ---- Check for client mode ----
                || (currentGroup?.groupType === 'client' && currentGroup?.id === currentClient?.id)
            )
            // ---- Client verification ----
            && (currentClient && (currentClient.id === config?.client_id || currentClient?.id === ''))
            // ---- Initialization and Config check ----
            && isClientInit && !configNotValid
        ) {
            setIsLoading(false);

            // ---- Update manully the scroll hook ----
            manualUpdateContainerValues();

            // ---- When we are in all mode we navigate to dashboard if we are not already in it ----
            if (currentClient?.id === '' && pathname !== '/dashboard') {
                navigate('/dashboard');
            }

            return undefined;
        }

        return undefined;
    }, [searchParams, config, groupConfig, groupExperiences, groupExperienceClient, experiences, currentGroup, currentClient,
        clients, customGroups, configNotValid, selectedExperience]);

    useEffect(() => {
        dispatch(setConfig(config || null));
    }, [config]);

    useEffect(() => {
        let pagePath = pathname.split('/')[1] || 'home';

        if (pagePath === 'saved-outfits') {
            pagePath = 'outfits';
        }

        const title = t(`page_names.${pagePath}`);

        if (title === `page_names.${pagePath}`) {
            setPageName('');
            document.title = 'Veesual';
        } else {
            setPageName(title);
            let clientTitle = '';

            // ---- If no ID in current Client and if we have a group ----
            if (currentClient && !currentClient.id && currentGroup) {
                clientTitle = currentGroup.name;
            }

            // ---- If we didn't set the clientTitle and we have a client ----
            if (currentClient && clientTitle === '') {
                clientTitle = currentClient.name;
            }

            document.title = `${title} | Veesual${clientTitle !== '' ? ` - ${clientTitle}` : ''}`;
        }
    }, [pathname, currentClient, currentGroup]);

    const navButtons = [
        {
            icon: Home,
            label: t('navigation_menu.home'),
            url: '/',
        },
        {
            icon: Dashboard,
            label: t('navigation_menu.dashboard'),
            url: '/dashboard',
        },
        {
            icon: Garment,
            label: t('navigation_menu.garment'),
            url: '/garment',
        },
        {
            icon: User,
            label: t('navigation_menu.model'),
            url: '/model',
        },
        {
            icon: SettingsIcon,
            label: t('navigation_menu.looks'),
            url: '/looks',
        },
        {
            icon: RepeatIcon,
            label: t('navigation_menu.outfit'),
            url: '/outfit',
        },
    ];

    function clickLogout() {
        handleLogout(dispatch);
        navigate('/login');
    }

    useEffect(() => {
        dispatch(setGroupConfig(groupConfig || null));
    }, [groupConfig]);

    // Clear all cached data used by Outfit page
    function clearOutfitData() {
        // Clear the search params
        searchParams.delete('model_id');
        searchParams.delete('garment_id');
        searchParams.delete('look_id');
        setSearchParams(searchParams);
    }

    function handleSelectBrand(option: OnChangeValue<CustomGroup, false>) {
        let newClient = null;

        // If no option or if we selected the current we do nothing
        if (!option || option.id === currentGroup?.id) {
            return;
        }

        // We set the clientId and remove the rest so it is set to correct value in main useEffect
        [newClient] = option.clients;
        searchParams.set('clientId', newClient.id);
        searchParams.delete('experienceId');
        searchParams.delete('groupId');

        // We remove the experience here so it doesn't do a request with wrong experience ID
        dispatch(setExperience(null));

        setSearchParams(searchParams);
        clearOutfitData();
    }

    function handleSelectDomain(option: OnChangeValue<ClientResponse, false>) {
        if (!option || option.id === currentClient?.id) {
            return;
        }

        // Selected ALL
        if (option.internal === '') {
            searchParams.set('groupId', currentGroup?.id || '');
            searchParams.delete('clientId');
            setSearchParams(searchParams);

            return;
        }

        searchParams.set('clientId', option.id);
        searchParams.delete('groupId');
        setSearchParams(searchParams);

        clearOutfitData();
    }

    function handleSelectExperience(option: OnChangeValue<Experience, false>) {
        if (!option || option.id === selectedExperience?.id) {
            return;
        }

        searchParams.set('experienceId', option.id);
        setSearchParams(searchParams);
        clearOutfitData();
    }

    function handleRenderContent() {
        // If the request is not loading and did not succeed
        if (configError) {
            return (
                <Box paddingBottom={5}>
                    {t('network.default', { ns: ERROR_LOCALES })}
                    {configError}
                </Box>
            );
        }

        // If we are loading we show a spinner
        if (isLoading) {
            return <VStack alignItems="center" h="100%" justifyContent="center" w="100%"><Spinner /></VStack>;
        }

        return <Outlet />;
    }

    const hasGroup = !!groupConfigSlice;

    const notDefault = hasGroup || isAll;

    // We chose the domainOptions array according to selected group
    const domainOptions = useMemo(() => {
        if (!notDefault) {
            return [{ ...currentClient, name: t('default') }];
        }

        if (currentGroup?.groupType === 'group') {
            return groupConfigSlice?.enable_all
                ? [{ id: '', internal: '', name: t('all') }, ...(groupExperienceClient || [])]
                : groupExperienceClient;
        }

        return clients;
    }, [currentGroup, clients, groupConfigSlice, notDefault, groupExperienceClient]);

    return (
        <Flex bg="gray.50" direction="row" height="100vh" overflow="hidden" position="relative" width="100vw">
            <VStack
                align="center"
                bg="white"
                height="100%"
                justify="space-between"
                minW={leftMenuWidth}
                position="absolute"
                w={leftMenuWidth}
            >
                <VStack height="100%" w={leftMenuWidth}>
                    {
                        isScrollableTop && <IconButton
                            aria-label='left-menu-scroll-up'
                            h="24px"
                            icon={<ArrowUpIcon />}
                            minW="24px"
                            onClick={scrollTop}
                            position="absolute"
                            top={arrowTopOffset}
                            w="24px"
                            zIndex={1}
                        />
                    }
                    {
                        isScrollableBottom && <IconButton
                            aria-label='left-menu-scroll-down'
                            bottom="8px"
                            h="24px"
                            icon={<ArrowDownIcon />}
                            minW="24px"
                            onClick={scrollBottom}
                            position="absolute"
                            w="24px"
                            zIndex={1}
                        />
                    }
                    <VeesualSquare cursor="pointer" mb={2} onClick={() => navigate('/')} pt={3} w="50%" />
                    <VStack height="100%" overflowX="hidden" overflowY="auto" ref={setLeftMenuElement} spacing={0} w={leftMenuWidth}>
                        {
                            currentClient && user && !configNotValid
                                ? navButtons.map((button) => {
                                    if (isAll && button.url !== '/dashboard') {
                                        return null;
                                    }

                                    if (button.url === '/dashboard'
                                    && (
                                        configSlice?.platform_enable_dashboard === false
                                        || (currentClient.id === '' && groupConfigSlice?.platform_enable_dashboard === false)
                                    )
                                    ) {
                                        return null;
                                    }

                                    if (button.url === '/outfit' && (!config?.platform_enable_outfit || !config?.experience_iframe_url)) {
                                        return null;
                                    }

                                    if (button.url === '/looks' && !config?.platform_enable_looks) {
                                        return null;
                                    }

                                    return (
                                        <NavigationButton
                                            MenuIcon={button.icon}
                                            current={isCurrentPage(button.url)}
                                            key={button.label}
                                            label={button.label}
                                            onClick={() => navigate(button.url)}
                                        />
                                    );
                                })
                                : <NavigationButton
                                    MenuIcon={navButtons[0].icon}
                                    current={isCurrentPage(navButtons[0].url)}
                                    key={navButtons[0].label}
                                    label={navButtons[0].label}
                                    onClick={() => navigate(navButtons[0].url)}
                                />
                        }
                        <NavigationButton
                            MenuIcon={ChatIcon}
                            current={isCurrentPage('/help')}
                            label={t('navigation_menu.help')}
                            onClick={supportMailTo}
                        />
                    </VStack>
                </VStack>
            </VStack>
            <HStack
                align="center"
                bg="white"
                h={topMenuHeight}
                justify="space-between"
                maxH={topMenuHeight}
                minH={topMenuHeight}
                ml={leftMenuWidth}
                position="absolute"
                px={6}
                width={`calc(100% - ${leftMenuWidth})`}
            >
                <HStack spacing={6}>
                    <Heading color="gray.700" fontSize="2xl" fontWeight="bold" ml={5}>{pageName}</Heading>
                    {/* <Button
                        _focus={{ boxShadow: 'none' }}
                        _hover={{
                            border: '1px solid',
                            borderColor: 'gray.700',
                            color: 'gray.500',
                        }}
                        border='2px solid'
                        borderColor="gray.100"
                        borderRadius="lg"
                        color="gray.700"
                        display={isCurrentPage('/outfit') ? 'flex' : 'none'}
                        h={12}
                        leftIcon={<Bookmark boxSize={5} />}
                        onClick={() => navigate('/saved-outfits')}
                        variant="unstyled"
                        w={44}
                    >
                        {t('saved_outfits')}
                    </Button> */}
                </HStack>
                <HStack zIndex={4}>
                    {
                        (clients && (experiences || groupExperiences) && customGroups)
                        && <>
                            {
                                clients.length > 1
                                && <CustomDropdown
                                    getOptionLabel={(group: CustomGroup) => group.name}
                                    getOptionValue={(group: CustomGroup) => group.id}
                                    onChange={handleSelectBrand}
                                    options={customGroups}
                                    placeholder={t('select_something', { something: t('brand') })}
                                    title={t('brand')}
                                    value={customGroups.find(
                                        (group) => group.id === currentGroup?.id
                                            || group.id === currentClient?.id,
                                    )}
                                />
                            }

                            {
                                clients.length >= 1
                                && <CustomDropdown
                                    disabled={!hasGroup && !isAll}
                                    getOptionLabel={(experience: Experience) => experience.name}
                                    getOptionValue={(experience: Experience) => experience.id}
                                    isOptionDisabled={
                                        (experience: Experience) => experiences?.findIndex(
                                            (localExp) => localExp.id === experience.id,
                                        ) === -1 && !isAll
                                    }
                                    onChange={handleSelectExperience}
                                    options={notDefault
                                        ? groupExperiences || experiences
                                        : [{ id: '', name: t('default') }]
                                    }
                                    placeholder={t('select_something', { something: t('experience') })}
                                    title={t('experience')}
                                    value={notDefault ? selectedExperience : { id: '', name: t('default') }}
                                />
                            }

                            {
                                clients.length > 1
                                && <CustomDropdown
                                    disabled={!hasGroup && !isAll}
                                    getOptionLabel={(client: ClientResponse) => client.name}
                                    getOptionValue={(client: ClientResponse) => client.id}
                                    onChange={handleSelectDomain}
                                    options={domainOptions}
                                    placeholder={t('select_something', { something: t('domain') })}
                                    title={t('domain')}
                                    value={notDefault ? currentClient : { ...currentClient, name: t('default') }}
                                />
                            }

                        </>
                    }
                </HStack>
                <HStack zIndex={3}>
                    {
                        isUserSuccess && user
                        && <Menu>
                            <MenuButton _hover={{ bg: 'gray.100' }} borderRadius="lg" px={2} py={1.5}>
                                <HStack>
                                    <Avatar boxSize={9} name={`${user?.firstname} ${user?.lastname}`} />
                                    <VStack align="flex-start" pr={5} spacing={0}>
                                        <Text _hover={{ color: 'primary.500' }} color="gray.500" fontSize="xs">{currentClient?.name}</Text>
                                        <Text fontSize="sm" fontWeight="semibold">{`${user?.firstname} ${user?.lastname}`}</Text>
                                    </VStack>
                                    <ChevronDownIcon />
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => navigate('/profile')}>{t('user_menu.profile')}</MenuItem>
                                <MenuItem onClick={clickLogout}>{t('user_menu.logout')}</MenuItem>
                            </MenuList>
                        </Menu>
                    }
                </HStack>
            </HStack>
            <Container
                maxW="1900px"
                ml={leftMenuWidth}
                mt={topMenuHeight}
                overflowY="auto"
                pl={10}
                pr={6}
                pt={4}
                w="full"
            >
                {handleRenderContent()}
            </Container>
        </Flex>
    );
};

export default AuthLayout;
