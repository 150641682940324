import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        background: '#E5E5E5',
        gray: {
            ...baseTheme.colors.gray,
            100: '#F3F4F6',
            200: '#E5E7EB',
            400: '#9CA3AF',
            50: '#F9FAFB',
            500: '#4B5563',
            700: '#1F2937',
        },
        primary: {
            ...baseTheme.colors.blue,
            100: '#F6F2FF',
            500: '#521AD5',
            700: '#2A067E',
        },
        state: {
            danger: '#DC2626',
            info: '#3B82F6',
            success: '#10B981',
            warning: '#F59E0B',
        },
    },
    components: {
        Accordion: {
            baseStyle: {
                button: {
                    _focus: {
                        boxShadow: 'none',
                    },
                    _hover: {
                        bg: 'white',
                    },
                    bg: 'white',
                    borderRadius: 'md',
                    fontWeight: 600,
                },
                container: {
                    bg: 'white',
                    border: 0,
                    borderRadius: 'md',
                },
            },
        },
        Button: {
            variants: {
                ghost: {
                    _focus: {
                        boxShadow: 'none',
                    },
                    border: '2px solid',
                    borderColor: 'transparent',
                    paddingX: 4,
                    paddingY: 6,
                },
                link: {
                    _focus: {
                        boxShadow: 'none',
                    },
                    color: 'primary.500',
                },
                navigation: {
                    _disabled: {
                        bg: 'white',
                        color: '#521AD5 !important',
                        cursor: 'default',
                        fontWeight: '700 !important',
                        opacity: 'full',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                    _hover: {
                        bg: 'gray.50',
                        color: 'gray.700',
                        fontWeight: 'bold',
                    },
                    borderRadius: 'md',
                    fontSize: 'xs',
                    height: 20,
                    margin: 2.5,
                    width: 20,
                },
                outfit: {
                    _active: {
                        bg: 'white',
                        border: '2px solid',
                        borderColor: 'primary.500',
                        color: 'primary.500',
                    },
                    _disabled: {
                        _active: {
                            color: 'gray.400',
                        },
                        bg: 'white !important',
                        borderColor: 'white !important',
                        color: 'gray.400',
                        opacity: 'full',
                    },
                    _focus: {
                        borderColor: 'white',
                        boxShadow: '0px 10px 50px rgba(31, 41, 55, 0.05)',
                    },
                    _hover: {
                        bg: 'white',
                        border: '4px solid',
                        borderColor: 'gray.200',
                    },
                    bg: 'white',
                    borderRadius: '2xl',
                    boxShadow: '0px 10px 50px rgba(31, 41, 55, 0.05)',
                    color: 'gray.700',
                    flexDirection: 'column',
                    height: 24,
                    width: 20,
                },
                outline: {
                    _active: {
                        bg: 'primary.100',
                        borderColor: 'primary.700',
                        color: 'primary.700',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                    _hover: {
                        bg: 'primary.100',
                        borderColor: 'primary.700',
                        color: 'primary.700',
                    },
                    bg: 'transparent',
                    border: '2px solid',
                    borderColor: 'primary.500',
                    borderRadius: 'lg',
                    color: 'primary.500',
                    paddingX: 4,
                    paddingY: 6,
                },
                red: {
                    _active: {
                        bg: 'red',
                        borderColor: 'red',
                    },
                    _focus: {
                        boxShadow: '0px 10px 30px rgba(82, 26, 213, 0.2)',
                    },
                    _hover: {
                        bg: '#DD0000 !important',
                        borderColor: '#DD0000 !important',
                    },
                    bg: 'red',
                    border: '2px solid',
                    borderColor: 'red',
                    borderRadius: 'lg',
                    boxShadow: '0px 10px 30px rgba(82, 26, 213, 0.2)',
                    color: 'white',
                    fontWeight: 'bold',
                    paddingX: 4,
                    paddingY: 6,
                },
                solid: {
                    _active: {
                        bg: 'primary.700',
                        borderColor: 'primary.700',
                    },
                    _focus: {
                        boxShadow: '0px 10px 30px rgba(82, 26, 213, 0.2)',
                    },
                    _hover: {
                        bg: '#2A067E !important',
                        borderColor: 'primary.700',
                    },
                    bg: 'primary.500',
                    border: '2px solid',
                    borderColor: 'primary.500',
                    borderRadius: 'lg',
                    boxShadow: '0px 10px 30px rgba(82, 26, 213, 0.2)',
                    color: 'white',
                    fontWeight: 'bold',
                    paddingX: 4,
                    paddingY: 6,
                },
                'tab-like': {
                    _active: {
                        bg: 'primary.500',
                        color: 'white',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                    _hover: {
                        bg: 'primary.500',
                        color: 'white',
                    },
                    bg: 'gray.100',
                    borderRadius: 'full',
                },
                white: {
                    _active: {
                        bg: 'gray.100',
                        borderColor: 'gray.100',
                        color: 'gray.700',
                    },
                    _disabled: {
                        bg: 'white !important',
                        borderColor: 'white !important',
                        opacity: 'full',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                    _hover: {
                        bg: 'gray.100',
                        borderColor: 'gray.100',
                        color: 'gray.700',
                    },
                    bg: 'white',
                    border: '2px solid',
                    borderColor: 'white',
                    borderRadius: 'lg',
                    color: 'gray.500',
                    paddingX: 4,
                    paddingY: 6,
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: {
                        _focus: {
                            bg: 'primary.500',
                            border: 'none',
                            boxShadow: 'none',
                        },
                        _hover: {
                            bg: 'primary.500',
                            border: 'none',
                        },
                        bg: 'primary.500',
                        border: 'none',
                        borderColor: 'primary.500',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                    bg: 'white',
                    borderColor: 'gray.400',
                    borderWidth: '1px',
                },
                icon: {
                    bg: 'primary.500',
                },
            },
            variants: {
                gray: {
                    control: {
                        _checked: {
                            _focus: {
                                bg: 'gray.500',
                                border: 'none',
                                boxShadow: 'none',
                            },
                            _hover: {
                                bg: 'gray.500',
                                border: 'none',
                            },
                            bg: 'gray.500',
                            border: 'none',
                            borderColor: 'gray.500',
                        },
                        _focus: {
                            boxShadow: 'none',
                        },
                        bg: 'white',
                        borderColor: 'gray.400',
                        borderWidth: '1px',
                    },
                    icon: {
                        bg: 'gray.500',
                    },
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: 'gray.200',
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: 'semibold',
            },
        },
        Input: {
            variants: {
                outline: {
                    field: {
                        _focus: {
                            borderColor: 'primary.500',
                            boxShadow: 'none',
                        },
                        borderColor: 'gray.200',
                        mb: 0,
                    },
                },
            },
        },
        Link: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none',
                },
                color: 'primary.500',
                fontSize: 'sm',
                textDecoration: 'underline',
            },
        },
        Modal: {
            baseStyle: {
                body: {
                    textAlign: 'center',
                },
                closeButton: {
                    _focus: {
                        boxShadow: 'none',
                    },
                },
                footer: {
                    justifyContent: 'center',
                },
                header: {
                    textAlign: 'center',
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    _focus: {
                        boxShadow: 'none',
                    },
                },
            },
        },
        Slider: {
            baseStyle: {
                filledTrack: {
                    bg: 'primary.500',
                    height: 0.5,
                },
                thumb: {
                    _focus: {
                        boxShadow: 'none',
                    },
                    bg: 'primary.500',
                },
            },
            variants: {
                gray: {
                    filledTrack: {
                        bg: 'gray.500',
                        height: 0.5,
                    },
                    thumb: {
                        _focus: {
                            boxShadow: 'none',
                        },
                        bg: 'gray.500',
                    },
                },
            },
        },
        Switch: {
            defaultProps: {
                colorScheme: 'primary',
            },
        },
        Table: {
            variants: {
                simple: {
                    td: {
                        borderColor: 'gray.200',
                    },
                    th: {
                        borderColor: 'gray.200',
                        color: 'gray.400',
                    },
                },
            },
        },
        Tabs: {
            variants: {
                'blue-buttons': {
                    tab: {
                        _focus: {
                            boxShadow: 'none',
                        },
                        _selected: {
                            bg: 'primary.500',
                            color: 'white',
                        },
                        bg: 'gray.100',
                        borderRadius: 'full',
                        color: 'gray.500',
                        fontWeight: 600,
                        height: 10,
                        marginX: 2,
                    },
                    tabpanel: {
                        boxSize: '100%',
                        padding: 0,
                    },
                },
                'blue-top': {
                    tab: {
                        _focus: {
                            boxShadow: 'none',
                        },
                        _hover: {
                            bg: 'gray.100',
                        },
                        _selected: {
                            borderTopColor: 'primary.500',
                        },
                        border: '3px solid',
                        borderColor: 'transparent',
                    },
                    tabpanel: {
                        boxSize: 'full',
                        padding: 0,
                    },
                },
            },
        },
        Tag: {
            variants: {
                active: {
                    container: {
                        bg: 'white',
                        borderRadius: 'full',
                        color: 'state.success',
                    },
                },
                inactive: {
                    container: {
                        bg: 'gray.200',
                        borderRadius: 'full',
                        color: 'gray.500',
                    },
                },
            },
        },
        Tooltip: {
            baseStyle: {
                borderRadius: 'md',
            },
        },
    },
    fonts: {
        body: `Raleway, ${baseTheme.fonts?.body}`,
        heading: `Raleway, ${baseTheme.fonts?.heading}`,
    },
    styles: {
        global: {
            body: {
                color: 'gray.500',
            },
            heading: {
                color: 'gray.500',
            },
            'html, body': {
                fontFamily: `Raleway, ${baseTheme.fonts?.body}`,
                lineHeight: '1.5',
            },
        },
    },
});

export default theme;
