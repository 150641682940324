import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { PasswordField } from '../components/fields';
import UnclosableBanner from '../components/UnclosableBanner';
import { useChangeForgotPasswordMutation, useCheckTokenQuery } from '../services/api/api-auth';
import { ErrorMutation } from '../types/api-types';
import { AUTH_LOCALES, ERROR_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';

const ResetPassword = () => {
    const params = useParams();
    const navigate = useCustomNavigate();
    const { t } = useTranslation([AUTH_LOCALES, ERROR_LOCALES]);

    const [changePassword, { error }] = useChangeForgotPasswordMutation();

    const { error: errorToken } = useCheckTokenQuery(params.token || '');

    async function handleSubmit(values: {password: string}) {
        try {
            await changePassword({ password: values.password, token: params.token || '' }).unwrap();
            navigate('/', { replace: true });
        } catch (e) {
            // Error handled in the JSX thanks to RTK Query
        }
    }

    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, t('password.too_short', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
    });

    return (
        <>
            <Flex boxSize="full" minH="520px">
                <VStack justify="center" spacing={8} w={{ base: 'full', lg: '60vw' }}>
                    <Image h="7" src='/assets/illustrations/logo.svg' w={48} />
                    <VStack align="flex-start">
                        <Button
                            fontWeight={500}
                            leftIcon={<ChevronLeftIcon boxSize={6} />}
                            onClick={() => navigate('/login')}
                            variant="link"
                        >
                            {t('reset_password.back_to_login_label')}
                        </Button>
                        <VStack
                            align="flex-start"
                            bg="white"
                            borderRadius="2xl"
                            px={8}
                            py={10}
                            spacing={10}
                            sx={{ '&>form': { w: 'full' } }}
                            w="590px"
                        >
                            <Heading color="black" fontSize="2xl">{t('reset_password.title')}</Heading>
                            {
                                errorToken
                                    ? <Text>{(errorToken as ErrorMutation).data.message || t('unknown', { ns: 'errors' })}</Text>
                                    : <Formik
                                        initialValues={{
                                            password: '',
                                        }}
                                        onSubmit={handleSubmit}
                                        validationSchema={ResetPasswordSchema}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <VStack spacing={10} w="full">
                                                    <VStack align="flex-end" spacing={6} w="full">
                                                        {error
                                                            && <UnclosableBanner
                                                                description={
                                                                    (error as ErrorMutation).data.message
                                                                    || t('unknown', { ns: ERROR_LOCALES })
                                                                }
                                                                title={t('submit', { ns: ERROR_LOCALES })}
                                                                type="error"
                                                            />
                                                        }
                                                        <PasswordField
                                                            label={t('reset_password.password.label')}
                                                            name="password"
                                                            placeholder={t('reset_password.password.placeholder')}
                                                        />
                                                    </VStack>
                                                    <Button
                                                        isLoading={isSubmitting}
                                                        type="submit"
                                                        variant="solid"
                                                        w="full"
                                                    >
                                                        {t('reset_password.submit_label')}
                                                    </Button>
                                                </VStack>
                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </VStack>
                    </VStack>

                </VStack>
            </Flex>
            <Image
                bottom={0}
                boxSize="full"
                display={{ base: 'none', lg: 'block' }}
                fit="contain"
                maxW="35vw"
                pointerEvents="none"
                position="fixed"
                right={0}
                src="/assets/illustrations/heroes.svg"
                top={0}
            />
        </>
    );
};

export default ResetPassword;
