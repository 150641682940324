/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Model = (props: IconProps) => (
    <Icon viewBox="0 0 41 40" {...props}>
        <path d="M33.52 10.144a5.184 5.184 0 0 0 1.824-3.949A5.201 5.201 0 0 0 30.148 1a5.201 5.201 0 0 0-5.195 5.195c0 1.58.71 2.995 1.825 3.949a6.308 6.308 0 0 0-2.938 5.329v9.648c0 .615.498 1.113 1.113 1.113h1.113v11.653c0 .614.499 1.113 1.114 1.113h5.937c.615 0 1.114-.498 1.114-1.113V26.234h1.113c.615 0 1.113-.498 1.113-1.113v-9.648a6.308 6.308 0 0 0-2.938-5.329Zm-6.34-3.949a2.972 2.972 0 0 1 2.968-2.968 2.972 2.972 0 0 1 2.97 2.968 2.972 2.972 0 0 1-2.97 2.97 2.972 2.972 0 0 1-2.968-2.97Zm4.824 30.578h-3.711V26.234h3.71v10.54Zm2.227-12.765h-8.165v-8.535a4.087 4.087 0 0 1 4.082-4.082 4.087 4.087 0 0 1 4.082 4.082v8.535ZM17.22 18.441l1.204-3.609a4.31 4.31 0 0 0-2.178-5.221 5.172 5.172 0 0 0 1.285-3.416A5.201 5.201 0 0 0 12.336 1 5.201 5.201 0 0 0 7.14 6.195c0 1.307.486 2.502 1.285 3.416a4.31 4.31 0 0 0-2.178 5.22l1.204 3.61L4.6 26.996a1.113 1.113 0 0 0 1.056 1.465h2.598v9.426c0 .614.498 1.113 1.113 1.113h5.938c.614 0 1.113-.498 1.113-1.113V28.46h2.598a1.113 1.113 0 0 0 1.056-1.465L17.22 18.44ZM12.336 3.227a2.972 2.972 0 0 1 2.969 2.968 2.972 2.972 0 0 1-2.97 2.97 2.972 2.972 0 0 1-2.968-2.97 2.972 2.972 0 0 1 2.969-2.968Zm1.855 33.546h-3.71v-8.312h3.71v8.312Zm-6.99-10.539 2.48-7.44a1.113 1.113 0 0 0 0-.705l-1.32-3.961a2.082 2.082 0 0 1 1.973-2.737h4.005a2.082 2.082 0 0 1 1.972 2.737l-1.32 3.961a1.114 1.114 0 0 0 0 .704l2.48 7.441H7.201Z" fill="currentColor"/>
    </Icon>
);

export default Model;
