/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const MultipleImages = (props: IconProps) => (
    <Icon viewBox="0 0 488.657 488.657" {...props} fill="currentColor">
        <path d="M448.104,0.006H192.858c-22.363,0-40.551,18.193-40.551,40.547v35.598h-35.611c-22.363,0-40.551,18.195-40.551,40.55
            v35.614H40.549C18.184,152.315,0,170.51,0,192.862v255.243c0,22.352,18.184,40.547,40.549,40.547h255.249
            c22.357,0,40.545-18.195,40.545-40.547v-35.608h35.619c22.361,0,40.547-18.195,40.547-40.549V336.34h35.596
            c22.365,0,40.553-18.192,40.553-40.547V40.553C488.657,18.198,470.47,0.006,448.104,0.006z M34.356,192.862
            c0-3.413,2.785-6.191,6.192-6.191h255.249c3.405,0,6.192,2.778,6.192,6.191V375.79l-77.825-5.778l-35.668-40.91
            c-4.563-5.199-12.385-5.895-17.75-1.602l-29.004,23.135l-32.377-72.709c-1.951-4.36-6.158-7.297-10.926-7.615
            c-4.816-0.345-9.311,2.038-11.826,6.098L34.356,361.23V192.862z M301.99,408.731H35.362l60.379-98.016l29.223,65.621
            c1.631,3.64,4.85,6.325,8.727,7.273c3.84,0.947,7.951,0.049,11.088-2.441l32.396-25.836l31.051,35.608
            c2.23,2.55,5.385,4.126,8.758,4.378l85.007,6.315V408.731z M301.99,448.105c0,3.414-2.787,6.19-6.192,6.19H40.549
            c-3.407,0-6.192-2.776-6.192-6.19v-13.606H301.99V448.105z M378.149,371.948c0,3.417-2.783,6.19-6.188,6.19h-35.619V192.862
            c0-22.352-18.188-40.547-40.545-40.547H110.507V116.7c0-3.416,2.781-6.19,6.189-6.19h255.266c3.404,0,6.188,2.775,6.188,6.19
            V371.948z M454.298,295.793c0,3.413-2.785,6.19-6.193,6.19h-35.596V116.7c0-22.354-18.185-40.55-40.547-40.55H186.667V40.553
            c0-3.413,2.785-6.19,6.191-6.19h255.246c3.408,0,6.193,2.777,6.193,6.19V295.793z"/>
        <path d="M219.854,294.719c22.155,0,40.144-17.966,40.144-40.137c0-22.159-17.988-40.128-40.144-40.128
            c-22.161,0-40.148,17.968-40.148,40.128C179.706,276.753,197.692,294.719,219.854,294.719z"/>
    </Icon>
);

export default MultipleImages;
