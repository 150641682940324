import { Divider, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Email, Informations, Password } from '../components/profile';
import { PROFILE_LOCALES } from '../utils/constants';

const Profile = () => {
    const { t } = useTranslation(PROFILE_LOCALES, { keyPrefix: 'tabs' });

    const tabs = [
        { Component: <Informations />, name: t('informations') },
        { Component: <Email />, name: t('email') },
        { Component: <Password />, name: t('password') },
    ];

    return (
        <VStack align="flex-start" pb={6} spacing={3}>
            <Heading fontSize="md">{t('title')}</Heading>
            <Tabs boxSize="full" variant="blue-buttons">
                <TabList>
                    <Wrap>
                        {tabs.map((tab) => <Tab key={tab.name}>{tab.name}</Tab>)}
                    </Wrap>
                </TabList>
                <Divider borderColor="gray.200" mb={8} mt={6} />
                <TabPanels>
                    {tabs.map((tab) => <TabPanel alignItems="flex-start" display="flex" key={tab.name}>{tab.Component}</TabPanel>)}
                </TabPanels>
            </Tabs>
        </VStack>
    );
};

export default Profile;
