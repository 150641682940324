/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Bookmark = (props: IconProps) => (
    <Icon {...props}>
        <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2Zm0 15-5-2.18L7 18V5h10v13Z" fill="currentColor"/>
    </Icon>
);

export default Bookmark;
