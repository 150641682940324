import { Box, Button, Checkbox, Fade, Image, SlideFade, Tag, Text, VStack } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { Garment, Model } from '../../types/api-types';
import isModel from '../../types/type-guards';
import { PRODUCTS_LOCALES } from '../../utils/constants';
import resizeImage from '../../utils/image';
import { useOutfitContext } from './OutfitContext';
import { useProductsContext } from './ProductsContext';

interface ProductProps {
    product: Model | Garment;
    onViewAllClick?(product: Model): void,
    imageRatio?: string
}

const Product: FC<ProductProps> = ({ product, onViewAllClick, imageRatio }) => {
    const { t } = useTranslation(PRODUCTS_LOCALES, { keyPrefix: 'grid' });

    const OutfitContext = useOutfitContext();
    const ProductContext = useProductsContext();
    const [onMouseOver, setOnMouseOver] = useState<boolean>(false);

    const isModelType = isModel(product);

    let productId = '';
    if (isModelType) {
        productId = product.model_id;
    } else {
        productId = product.garment_id;
    }

    const isChecked = useCallback(
        () => {
            let selectProduct = null;

            if (OutfitContext?.selectedProduct?.id === productId) {
                return true;
            }

            if (ProductContext?.checkAll) {
                return true;
            }

            if (ProductContext?.selectedProducts) {
                selectProduct = ProductContext?.selectedProducts.find((selectedProduct) => selectedProduct.id === productId);

                return !!selectProduct;
            }

            return !!selectProduct;
        },
        [OutfitContext, ProductContext],
    );

    const handleCheck = () => {
        if (OutfitContext?.onProductClick) {
            return OutfitContext?.onProductClick({ id: productId, name: isModelType ? product.identity_name : product.name, url: product.image_url });
        }

        if (ProductContext?.selectAProduct) {
            if (isChecked()) {
                return ProductContext?.deselectAProduct(productId);
            }

            return ProductContext?.selectAProduct(productId);
        }

        return null;
    };

    const handleSeeAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (onViewAllClick && isModel(product)) {
            onViewAllClick(product);
        }
    };

    return (
        <Box
            h={0}
            onMouseEnter={() => setOnMouseOver(true)}
            onMouseLeave={() => setOnMouseOver(false)}
            overflow="hidden"
            paddingBottom={imageRatio}
            position="relative"
            w="100%"
        >
            <LazyLoad height="100%" offset={100} once overflow>
                <Image
                    border="2px solid"
                    borderColor={isChecked() ? 'primary.500' : 'transparent'}
                    borderRadius="lg"
                    cursor="pointer"
                    height="100%"
                    objectFit={isModelType ? 'cover' : 'contain'}
                    onClick={handleCheck}
                    position="absolute"
                    src={resizeImage(product.image_url, { width: 800 })}
                    width="100%"
                />
            </LazyLoad>

            <Checkbox
                display={OutfitContext?.onProductClick ? 'none' : 'block'}
                isChecked={isChecked()}
                left={4}
                onChange={handleCheck}
                position="absolute"
                size="lg"
                top={4}
            />
            <Tag
                cursor="pointer"
                onClick={handleCheck}
                position="absolute"
                right={2}
                top={2}
                variant={product.published ? 'active' : 'inactive'}
            >
                {product.published ? t('product_status.active') : t('product_status.inactive')}
            </Tag>
            {
                (isModelType && onViewAllClick)
                && <Fade in={onMouseOver} transition={{ enter: { duration: 0.4 } }} unmountOnExit={true}>
                    <VStack
                        bg="linear-gradient(transparent 55%, rgba(0, 0, 0, 0.8))"
                        borderRadius="lg"
                        bottom={0}
                        cursor="pointer"
                        justify="flex-end"
                        left={0}
                        onClick={handleCheck}
                        p={4}
                        position="absolute"
                        right={0}
                        top={0}
                    >
                        <Button onClick={handleSeeAllClick} variant="solid">{t('see_all')}</Button>
                        <SlideFade in={onMouseOver} offsetY='40px' transition={{ enter: { duration: 0.4 } }} unmountOnExit={true}>
                            <Text align="center" color="white" fontSize="sm" fontWeight="semibold" lineHeight={5} noOfLines={4}>
                                {product.identity_name}
                                {product.identity_height ? `, ${product.identity_height}cm` : ''}
                                {product.identity_garment_size ? `, ${product.identity_garment_size}` : ''}
                            </Text>
                        </SlideFade>
                    </VStack>
                </Fade>
            }

        </Box>
    );
};

export default Product;
