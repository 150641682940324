import React from 'react';
import { useDispatch } from 'react-redux';

import { Filters } from '../components/filters/filtersTypes';
import ProductsLayout from '../components/products/ProductsLayout';
import { invalidateGetGarmentsAction, useGetGarmentsQuery } from '../services/api/api-garment';
import { getClient, getExperience } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { FacetTerms, FacetTermsData, GarmentQueryInput } from '../types/api-types';
import { PRODUCTS_CATEGORIES } from '../utils/constants';

let shouldRefresh = false;

const Garment = () => {
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const [productTypes, setProductTypes] = React.useState<FacetTermsData[]>([]);
    const [queryInputObject, setQueryInputObject] = React.useState<GarmentQueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
    });
    const { data: garmentResponse, isFetching: isLoadingGarments } = useGetGarmentsQuery(queryInputObject);
    const dispatch = useDispatch();

    function useGarmentQuery(filters: Filters, query = '', page = 1, collapse = null, forceRefetch = false) {
        if (currentClient) {
            setQueryInputObject({ clientId: currentClient.id, collapse, experienceId: selectedExperience?.id, filters, page, query });
        }
        if (forceRefetch) {
            shouldRefresh = true;
        }
    }

    React.useEffect(() => {
        if (shouldRefresh) {
            dispatch(invalidateGetGarmentsAction);
        }
        shouldRefresh = false;
    }, [queryInputObject]);

    React.useEffect(() => {
        if (productTypes.length === 0 && garmentResponse && garmentResponse.facets) {
            const foundData = Object.keys(garmentResponse.facets).find((facetKey) => facetKey === 'garment_type');
            if (foundData) {
                setProductTypes((garmentResponse.facets.garment_type as FacetTerms).data);
            }
        }
    }, [garmentResponse]);

    return (
        <ProductsLayout
            category={PRODUCTS_CATEGORIES.GARMENT}
            clientId={currentClient?.id || ''}
            dataQuery={useGarmentQuery}
            isLoading={isLoadingGarments}
            productTypes={productTypes}
            queryResponse={garmentResponse}
        />
    );
};

export default Garment;
