import { getClient, getConfig, getGroup, getGroupConfig } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';

export default function useConfigValidation() {
    const currentClient = useAppSelector((state) => getClient(state));
    const config = useAppSelector((state) => getConfig(state));
    const groupConfig = useAppSelector((state) => getGroupConfig(state));
    const currentGroup = useAppSelector((state) => getGroup(state));

    return !currentClient
    || (currentClient.id !== '' && !config)
    || (currentClient.id === '' && !currentGroup)
    || (currentGroup?.groupType === 'group' && !groupConfig)
    || !!(config && currentClient !== null && config.client_id !== currentClient.id);
}
