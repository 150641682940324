import { createSearchParams, NavigateOptions, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export default function useCustomNavigate() {
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    function navigateWithParams(targetUrl?: string, options?: NavigateOptions, params?: { [key: string]: string | null }) {
        // ---- If we have search params in targetUrl, bypass everything ----
        if (targetUrl) {
            const explodedUrl = targetUrl.split('?');
            if (explodedUrl.length > 1) {
                return navigate({
                    pathname: explodedUrl[0],
                    search: explodedUrl[1],
                }, options);
            }
        }

        if (params) {
            const finalParams = JSON.parse(JSON.stringify(params));
            searchParams.forEach((value, key) => {
                if (params[key] === null) {
                    delete finalParams[key];
                } else {
                    finalParams[key] = params[key] || value;
                }
            });

            return navigate({
                pathname: targetUrl || pathname,
                search: `${createSearchParams(finalParams)}`,
            }, options);
        }

        return navigate({
            pathname: targetUrl || pathname,
            search: `${createSearchParams(searchParams)}`,
        }, options);
    }

    return navigateWithParams;
}
