import { WarningTwoIcon } from '@chakra-ui/icons';
import { Button, HStack, Image, Spinner, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { invalidateGetGarmentAction, useGetGarmentQuery, usePutGarmentMutation } from '../services/api/api-garment';
import { getClient, getExperience } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { IframeLookData } from '../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, LOOKS_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';

export default function GarmentLookSetting() {
    const dispatch = useDispatch();
    const { garmentId } = useParams();
    const { t } = useTranslation([LOOKS_LOCALES, COMMON_LOCALES, ERROR_LOCALES]);
    const toast = useToast();
    const navigate = useCustomNavigate();

    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));

    const { data: garmentDetail, isLoading, isError } = useGetGarmentQuery({
        clientId: currentClient?.id || '',
        experienceId: selectedExperience?.id,
        id: garmentId || '',
    }, { skip: !currentClient || !garmentId });

    const [saveGarmentLook] = usePutGarmentMutation();

    const [look, setLook] = useState<IframeLookData>();

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const isInvalid = useMemo(() => {
        // ---- If it's loading we are not invalid ----
        if (isLoading) {
            return false;
        }

        // ---- We need the garment and look infos ----
        if (!garmentDetail || !look || !look.lookId) {
            return true;
        }

        // ---- If we don't have the garment id in the look id ----
        if (!look.lookId.match(garmentDetail.garment_id)) {
            return true;
        }

        return false;
    }, [look, garmentDetail, isLoading]);

    const handleRefreshClick = () => {
        if (!iframeRef?.current) {
            return;
        }

        // ---- This triggers the iframe refresh ----
        iframeRef.current.src += '';
    };

    const handleSaveClick = () => {
        // ---- Skip if we don't have the necessary data ----
        if (!currentClient || !garmentDetail || !look || !look.lookId) {
            return;
        }

        // ---- Trigger save method ----
        saveGarmentLook(
            { clientId: currentClient?.id, garmentId: garmentDetail?.garment_id, lookId: look.lookId, mode: look.mode },
        ).unwrap().then(() => {
            toast({
                isClosable: true,
                status: 'success',
                title: t('save_success'),
            });

            // ---- Invalidate the garment detail if save was successful so we get the new look image url to show ----
            dispatch(invalidateGetGarmentAction);
        }).catch(() => {
            toast({
                isClosable: true,
                status: 'error',
                title: t('save_error', { ns: ERROR_LOCALES }),
            });
        });
    };

    const handleDeleteClick = () => {
        // ---- Skip if we don't have the necessary data ----
        if (!currentClient || !garmentDetail) {
            return;
        }

        // ---- Trigger save method ----
        saveGarmentLook(
            { clientId: currentClient.id, garmentId: garmentDetail.garment_id },
        ).unwrap().then(() => {
            toast({
                isClosable: true,
                status: 'success',
                title: t('delete_success'),
            });

            // ---- Invalidate the garment detail if save was successful so we get the new look image url to show ----
            dispatch(invalidateGetGarmentAction);
        }).catch(() => {
            toast({
                isClosable: true,
                status: 'error',
                title: t('delete_error', { ns: ERROR_LOCALES }),
            });
        });
    };

    // ---- Plug/Unplug the message listener to get infos from iframe ----
    useEffect(() => {
        const handleIframeResponse = (event: MessageEvent) => {
            try {
                if (typeof event.data !== 'string') {
                    return;
                }

                // ---- The string we want to handle is formated like this: TYPE:DATA ----
                const splitted = event.data.split(':');

                // ---- Check if it's a message we want to handle (the one sending the look) and save it in the state ----
                if (splitted[0] === 'veesual_look_update') {
                    setLook(JSON.parse(splitted.slice(1).join(':')));
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('sendIframeMessageWithResponse exception');
            }
        };

        // ---- Plug listener ----
        window.addEventListener('message', handleIframeResponse);

        // ---- Clean up ----
        return () => {
            window.removeEventListener('message', handleIframeResponse);
        };
    }, []);

    // ---- Navigate to look garment list if we have an error on the garment detail request ----
    useEffect(() => {
        if (isError) {
            navigate('/looks');
        }
    }, [isError]);

    return (
        <VStack alignItems="center" h="100%" justifyContent="center" w="100%">
            {
                isLoading
                    ? <Spinner />
                    : <>
                        <HStack justifyContent="space-between" w="100%">
                            <HStack>
                                <Text fontSize="large" fontWeight="bold">{t('title')}</Text>
                                <Text fontSize="large">{`- ${t('description')}`}</Text>
                            </HStack>

                            {
                                isInvalid && <HStack color="orange">
                                    <WarningTwoIcon />
                                    <Text fontSize="sm">{t('warning_description')}</Text>
                                </HStack>
                            }
                            <HStack>
                                <Button
                                    isDisabled={!garmentDetail?.look?.look_image_url}
                                    onClick={handleDeleteClick}
                                    variant="red"
                                >
                                    {t('delete_look', { ns: LOOKS_LOCALES })}
                                </Button>
                                <Button onClick={handleRefreshClick} variant="outline">{t('refresh', { ns: COMMON_LOCALES })}</Button>
                                <Button isDisabled={isInvalid} onClick={handleSaveClick}>{t('save', { ns: COMMON_LOCALES })}</Button>
                            </HStack>
                        </HStack>
                        <HStack h="100%" overflowY="auto" spacing={0} w="100%">
                            {
                                garmentDetail && garmentDetail.look?.experience_iframe_url && <iframe
                                    height="100%"
                                    ref={iframeRef}
                                    src={garmentDetail.look?.experience_iframe_url}
                                    style={{ paddingBottom: 8 }}
                                    width="80%"
                                />
                            }
                            <VStack h="100%" overflowY="auto" w="20%">
                                <Text fontSize="large">{t('garment', { ns: COMMON_LOCALES })}</Text>
                                <Image
                                    h="30%"
                                    objectFit='contain'
                                    src={garmentDetail?.image_url}
                                />
                                <Text fontSize="large">{t('current_look')}</Text>
                                <Image
                                    h="30%"
                                    objectFit='contain'
                                    src={garmentDetail?.look?.look_image_url}
                                />
                            </VStack>
                        </HStack>
                    </>
            }

        </VStack>
    );
}
