/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const Garment = (props: IconProps) => (
    <Icon {...props}>
        <path d="M21.6 18.2 13 11.75v-.91a3.496 3.496 0 0 0-.18-6.75A3.51 3.51 0 0 0 8.5 7.5h2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5c0 .84-.69 1.52-1.53 1.5-.54-.01-.97.45-.97.99v1.76L2.4 18.2c-.77.58-.36 1.8.6 1.8h18c.96 0 1.37-1.22.6-1.8ZM6 18l6-4.5 6 4.5H6Z" fill="currentColor"/>
    </Icon>
);

export default Garment;
