import { Button, Divider, Heading, HStack, Text, VStack, Wrap } from '@chakra-ui/react';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Garment as GarmentIcon,
    Model as ModelIcon,
    Outfit as OutfitIcon,
} from '../assets/icons';
import MultipleImages from '../assets/icons/MultipleImages';
import { StatCard } from '../components/stats';
import { useGetClientCountQuery, useGetGroupCountQuery } from '../services/api/api-client';
import { getClient, getConfig, getExperience, getGroup, getGroupConfig } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { DASHBOARD_LOCALES, PRODUCTS_LOCALES } from '../utils/constants';

const Dashboard = () => {
    const { t } = useTranslation([DASHBOARD_LOCALES, PRODUCTS_LOCALES]);
    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const groupConfigSlice = useAppSelector((state) => getGroupConfig(state));
    const currentGroup = useAppSelector((state) => getGroup(state));

    const isAll = currentGroup?.groupType === 'group' && currentClient?.id === '';

    const { data: clientCount, isFetching: clientCountIsFetching } = useGetClientCountQuery({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
    }, { skip: isAll === true || !currentClient });

    const { data: groupCount, isFetching: groupCountIsFetching } = useGetGroupCountQuery({
        experienceId: selectedExperience ? selectedExperience.id : undefined,
        groupId: groupConfigSlice?.group_id || '',
    }, { skip: currentGroup?.groupType !== 'group' });

    const informativeSectionRef = useRef<null | HTMLDivElement>(null);
    const usageSectionRef = useRef<null | HTMLDivElement>(null);
    const businessSectionRef = useRef<null | HTMLDivElement>(null);
    const analyticsSectionRef = useRef<null | HTMLDivElement>(null);

    const scrollToRef = (ref: MutableRefObject<null | HTMLDivElement>) => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const dashboardConfig = groupConfigSlice && isAll ? groupConfigSlice : config;

    const isUsingConfig = !!dashboardConfig?.experience_dashboard_usage_html
        || !!dashboardConfig?.experience_dashboard_business_html
        || !!dashboardConfig?.experience_dashboard_analysis_html;

    return (
        <VStack boxSize="full" maxH="full" spacing={6}>
            {
                !dashboardConfig?.experience_dashboard_global_url
                && <VStack spacing={6} top={0} w="full">
                    <Wrap align="center" justify="flex-start" w="full">
                        <Text color="gray.400" pr={8}>{t('tabs.title')}</Text>
                        {
                            dashboardConfig?.platform_enable_counters === true && (
                                <Button onClick={() => scrollToRef(informativeSectionRef)} variant="tab-like">
                                    {t('tabs.informative')}
                                </Button>
                            )
                        }
                        {(dashboardConfig?.experience_dashboard_usage_html || !isUsingConfig) && (
                            <Button onClick={() => scrollToRef(usageSectionRef)} variant="tab-like">
                                {t('tabs.usage')}
                            </Button>
                        )}
                        {(dashboardConfig?.experience_dashboard_business_html || !isUsingConfig) && (
                            <Button onClick={() => scrollToRef(businessSectionRef)} variant="tab-like">
                                {t('tabs.business_perf')}
                            </Button>
                        )}
                        {(dashboardConfig?.experience_dashboard_analysis_html || !isUsingConfig) && (
                            <Button onClick={() => scrollToRef(analyticsSectionRef)} variant="tab-like">
                                {t('tabs.model_analysis')}
                            </Button>
                        )}
                    </Wrap>
                    <Divider borderColor="gray.200" />
                </VStack>
            }

            <VStack
                boxSize="full"
                overflowY="auto"
                pb={6}
                pr={6}
                spacing={12}
            >
                {
                    process.env.REACT_APP_DEV !== 'dev'
                        ? <>
                            {
                                dashboardConfig?.platform_enable_counters === true && (
                                    <VStack align="flex-start" ref={informativeSectionRef} spacing={4} w="full">
                                        <Heading fontSize="lg">{t('informative.title')}</Heading>
                                        <HStack spacing={4} w="full">
                                            <StatCard
                                                Icon={ModelIcon}
                                                label={t('informative.active_models')}
                                                loading={clientCountIsFetching && groupCountIsFetching}
                                                value={(isAll === true ? groupCount?.active_models : clientCount?.active_models) || 0}
                                            />
                                            <StatCard
                                                Icon={GarmentIcon}
                                                label={t('informative.active_garments')}
                                                loading={clientCountIsFetching && groupCountIsFetching}
                                                value={(isAll === true ? groupCount?.active_garments : clientCount?.active_garments) || 0}
                                            />
                                            <StatCard
                                                Icon={OutfitIcon}
                                                label={t('informative.potential_outfits')}
                                                loading={clientCountIsFetching && groupCountIsFetching}
                                                value={(isAll === true ? groupCount?.active_looks : clientCount?.active_looks) || 0}
                                            />
                                            <StatCard
                                                Icon={MultipleImages}
                                                label={t('informative.number_of_images')}
                                                loading={clientCountIsFetching && groupCountIsFetching}
                                                value={(isAll === true ? groupCount?.active_images : clientCount?.active_images) || 0}
                                            />
                                        </HStack>
                                    </VStack>
                                )
                            }
                            {
                                dashboardConfig?.experience_dashboard_global_url
                                    ? <VStack align="flex-start" h="full" ref={analyticsSectionRef} spacing={4} w="full">
                                        <iframe
                                            src={dashboardConfig.experience_dashboard_global_url}
                                            style={{ height: '100%', width: '100%' }}
                                        />
                                    </VStack>
                                    : <>
                                        {(
                                            <>
                                                {dashboardConfig?.experience_dashboard_usage_html && (
                                                    <VStack align="flex-start" ref={usageSectionRef} spacing={4} w="full">
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: dashboardConfig?.experience_dashboard_usage_html }}
                                                            style={{ width: '100%' }}
                                                        ></div>
                                                    </VStack>
                                                )}
                                                {dashboardConfig?.experience_dashboard_business_html && (
                                                    <VStack align="flex-start" ref={businessSectionRef} spacing={4} w="full">
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: dashboardConfig?.experience_dashboard_business_html }}
                                                            style={{ width: '100%' }}
                                                        ></div>
                                                    </VStack>
                                                )}
                                                {dashboardConfig?.experience_dashboard_analysis_html && (
                                                    <VStack align="flex-start" ref={analyticsSectionRef} spacing={4} w="full">
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: dashboardConfig?.experience_dashboard_analysis_html }}
                                                            style={{ width: '100%' }}
                                                        ></div>
                                                    </VStack>
                                                )}
                                            </>
                                        )}
                                    </>
                            }

                        </>
                        : <>
                            {currentClient?.name}
                            <VStack align="flex-start" ref={analyticsSectionRef} spacing={4} w="full">
                                <div
                                    style={{ width: '100%' }}
                                >
                                    <iframe style={{ backgroundColor: 'red', width: '100%' }}/>
                                </div>
                            </VStack>
                        </>
                }

            </VStack>
        </VStack>
    );
};

export default Dashboard;
