/* eslint-disable max-len */
import { Icon, IconProps } from '@chakra-ui/react';

const ViewOffIcon = (props: IconProps) => (
    <Icon viewBox="0 0 22 20" {...props}>
        <path d="M11 4a9.77 9.77 0 0 1 8.82 5.5 9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C20.27 5.11 16 2 11 2c-1.27 0-2.49.2-3.64.57l1.65 1.65C9.66 4.09 10.32 4 11 4ZM9.93 5.14 12 7.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C15.5 7.01 13.48 5 11 5c-.37 0-.72.05-1.07.14ZM1.01 1.87l2.68 2.68A11.738 11.738 0 0 0 0 9.5C1.73 13.89 6 17 11 17c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L2.42.45 1.01 1.87Zm7.5 7.5 2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13Zm-3.4-3.4 1.75 1.75A4.6 4.6 0 0 0 6.5 9.5a4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53Z" fill="currentColor"/>
    </Icon>
);

export default ViewOffIcon;
