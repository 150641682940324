import { Container, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const UnauthLayout = () => (
    <Flex bg="gray.50" h="100vh" overflow="hiden" w="100vw">
        <Container boxSize="full" maxW="none" overflow="auto">
            <Outlet />
        </Container>
    </Flex>
);

export default UnauthLayout;
