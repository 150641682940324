import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Heading, Image, SlideFade, Text, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HOME_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';

interface LinkTileProps {
    description: string;
    illustrationSrc: string;
    label: string;
    link: string;
}

const LinkTile: FC<LinkTileProps> = ({ description, illustrationSrc, label, link }) => {
    const navigate = useCustomNavigate();
    const { t } = useTranslation(HOME_LOCALES, { keyPrefix: 'page_bloc' });

    const [onMouseOver, setOnMouseOver] = useState<boolean>(false);
    const animationDuration = 0.4;

    return (
        <VStack
            _hover={{
                boxShadow: '0px 10px 50px rgba(31, 41, 55, 0.05)',
            }}
            bg="white"
            borderRadius="lg"
            boxSize="full"
            cursor="pointer"
            h="405px"
            onClick={() => navigate(link)}
            onMouseEnter={() => setOnMouseOver(true)}
            onMouseLeave={() => setOnMouseOver(false)}
            padding={8}
            spacing={8}
            transition={`box-shadow ${animationDuration}s ease-in-out`}
            w={60}
        >
            <Image h={32} src={illustrationSrc} w="auto" />
            <VStack align="flex-start" boxSize="full" spacing={1} w="full">
                <Heading fontSize="md">
                    {label}
                </Heading>
                <VStack align="flex-start" boxSize="full" justify="space-between" sx={{ '&>button': { h: 6 } }} textAlign="left">
                    <Text fontSize="sm">{description}</Text>
                    <Button
                        minW={0}
                        rightIcon={
                            <SlideFade
                                in={onMouseOver}
                                offsetX='-5px'
                                offsetY={0}
                                transition={{ enter: { duration: animationDuration }, exit: { duration: animationDuration } }}
                                unmountOnExit={true}
                            >
                                <ChevronRightIcon h={5} w="auto" />
                            </SlideFade>
                        }
                        textDecoration="underline"
                        variant="link">{t('link_label')}
                    </Button>
                </VStack>
            </VStack>
        </VStack>
    );
};

export default LinkTile;
