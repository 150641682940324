import { Center, Heading, HStack, Icon as ChakraIcon, Spinner, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface StatCardProps {
    Icon: typeof ChakraIcon;
    label: string;
    unit?: string;
    value: number;
    w?: number;
    loading?: boolean;
}

const StatCard: FC<StatCardProps> = ({ Icon, label, unit = null, value, w = undefined, loading = false }) => (
    <HStack bg="white" borderRadius="lg" padding={4} spacing={4} w={w || 'full'}>
        <Center bg="primary.100" borderRadius="base" boxSize={14}>
            <Icon boxSize={6} color="primary.500" />
        </Center>
        <VStack align="flex-start" spacing={1}>
            {
                loading
                    ? <Spinner />
                    : <Heading fontSize="2xl">
                        {`${value.toLocaleString('en', { minimumFractionDigits: 0 })}${unit || ''}`}
                    </Heading>
            }

            <Text fontSize="md">{label}</Text>
        </VStack>
    </HStack>
);

export default StatCard;
