import {
    Center,
    Checkbox,
    HStack,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spinner,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forceCheck } from 'react-lazyload';

import { Garment, Model } from '../../types/api-types';
import isModel from '../../types/type-guards';
import { PRODUCTS_LOCALES } from '../../utils/constants';
import Product from './Product';
import { useProductsContext } from './ProductsContext';

interface ProductsGridProps {
    products: Model[] | Garment[] | null;
    onScroll?(e: React.UIEvent<HTMLDivElement>): void;
    totalNumber?: number | null;
    totalDedup?: number | null;
    isLoading?: boolean;
    onViewAllClick?(model: Model): void;
    imagesRatio?: string // Ratio of the Product Images
}

const ProductsGrid = React.forwardRef<HTMLDivElement, ProductsGridProps>(({
    products, totalNumber, totalDedup, onScroll, isLoading, onViewAllClick, imagesRatio }, ref) => {
    const { t } = useTranslation(PRODUCTS_LOCALES, { keyPrefix: 'grid' });

    const ProductContext = useProductsContext();

    const defaultProductsPerRow = 4;
    const maxProductsPerRow = 6;
    const minProductsPerRow = 3;

    const [productsPerRow, setProductsPerRow] = useState<number>(defaultProductsPerRow);
    const updateProductsPerRow = (value: number) => {
        setProductsPerRow(minProductsPerRow + maxProductsPerRow - value);
        forceCheck();
    };

    function handleViewAllClick(product: Model) {
        if (onViewAllClick) {
            onViewAllClick(product);
        }
    }

    function handleCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
        if (!ProductContext) {
            return;
        }
        if (ProductContext?.selectedProducts.length > 0) {
            ProductContext.changeCheckAll(false);

            return;
        }
        ProductContext.changeCheckAll(e.target.checked);
    }

    useEffect(() => {
        window.addEventListener('resize', forceCheck);

        return () => window.removeEventListener('resize', forceCheck);
    }, []);

    return (
        <VStack boxSize="full" ref={ref}>
            <HStack justify="space-between" pr={12} width="full">
                {
                    ProductContext
                    && <Checkbox
                        isChecked={ProductContext?.checkAll}
                        isIndeterminate={!ProductContext?.checkAll && ProductContext?.selectedProducts.length > 0}
                        onChange={handleCheckbox}
                        variant="gray"
                    >
                        <Text color="gray.400">
                            {(ProductContext?.checkAll || ProductContext?.selectedProducts.length > 0) ? t('uncheck_all') : t('check_all')}
                        </Text>
                    </Checkbox>

                }

                <HStack>
                    <Text color="gray.400">{`${t('search_results')}: `}</Text>
                    <Text fontWeight="bold">{totalDedup || totalNumber}</Text>
                    {isLoading && <Spinner />}
                </HStack>
                <HStack>
                    <Text color="gray.400" mr={4}>{`${t('image_sizes')}: `}</Text>
                    <Slider
                        defaultValue={minProductsPerRow + maxProductsPerRow - defaultProductsPerRow}
                        max={maxProductsPerRow}
                        min={minProductsPerRow}
                        onChange={updateProductsPerRow}
                        step={1}
                        variant="gray"
                        w={120}
                    >
                        <SliderTrack h={0.5}>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </HStack>
            </HStack>
            {
                products
                && (
                    products.length < 1 && !isLoading
                        ? <Center boxSize="full">{t('no_product')}</Center>
                        : <Wrap boxSize="full" onScroll={onScroll} overflowY="auto" pb={6} pr={8} spacing={2}>
                            {
                                products.map((product, index) => (
                                    <WrapItem key={index} w={`calc(100% / ${productsPerRow} - 8px)`}>
                                        <Center boxSize="full">
                                            <Product
                                                imageRatio={imagesRatio}
                                                onViewAllClick={
                                                    (isModel(product) && onViewAllClick)
                                                        ? handleViewAllClick
                                                        : undefined
                                                }
                                                product={product}
                                            />
                                        </Center>
                                    </WrapItem>

                                ))
                            }
                        </Wrap>
                )
            }
        </VStack>
    );
});

export default ProductsGrid;
