import { useEffect, useMemo, useState } from 'react';

export default function useVerticalScroll(scrollElement: HTMLElement | null) {
    const [refScrollTop, setRefScrollTop] = useState<number>(0);
    const [refClientHeight, setRefClientHeight] = useState<number>(0);
    const [refScrollHeight, setRefScrollHeight] = useState<number>(0);

    const isScrollableTop = useMemo(() => refScrollTop !== 0, [refScrollTop]);
    const isScrollableBottom = useMemo(() => refScrollTop + refClientHeight < refScrollHeight, [refScrollTop, refClientHeight, refScrollHeight]);

    // ---- Store the different content width values in state so it update the scrollable check ----
    const updateContainerValues = (content: HTMLElement) => {
        setRefScrollTop(Math.ceil(content.scrollTop));
        setRefScrollHeight(content.scrollHeight);
        setRefClientHeight(content.clientHeight);
    };

    const scrollBottom = () => {
        scrollElement?.scrollTo({
            behavior: 'smooth',
            top:
                refScrollTop > refScrollHeight - refClientHeight
                    ? refScrollHeight
                    : refScrollTop + refClientHeight,
        });
    };

    const scrollTop = () => {
        scrollElement?.scrollTo({
            behavior: 'smooth',
            top:
                refScrollTop < scrollElement.clientHeight
                    ? 0
                    : refScrollTop - scrollElement.clientHeight,
        });
    };

    const handleScroll = (e: Event) => {
        updateContainerValues(e.currentTarget as HTMLElement);
    };

    const manualUpdateContainerValues = () => {
        if (!scrollElement) {
            return;
        }

        updateContainerValues(scrollElement);
    };

    useEffect(() => {
        if (!scrollElement) {
            return undefined;
        }

        updateContainerValues(scrollElement);

        // ---- Handle Resize of the scroll element ----
        function handleResize() {
            if (scrollElement) {
                updateContainerValues(scrollElement);
            }
        }

        // ---- Plug resizeObserver on scroll Element ----
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(scrollElement);

        // ---- Add on scroll event ----
        scrollElement.addEventListener('scroll', handleScroll);

        // ---- Clean up ----
        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
            resizeObserver.disconnect();
        };
    }, [scrollElement]);

    return {
        isScrollableBottom,
        isScrollableTop,
        manualUpdateContainerValues,
        scrollBottom,
        scrollTop,
    };
}
