import { LoginResponse } from '../types/api-types';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from './constants';
import { storeInLocalStorage } from './local-storage-helpers';

export const localTokenExist = () => {
    try {
        const rawToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const rawRefreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
        if (rawToken === null || rawRefreshToken === null) {
            return false;
        }

        return true;
    } catch (e) {
        return false;
    }
};

export const storeTokens = (tokens: LoginResponse) => {
    storeInLocalStorage(AUTH_TOKEN_KEY, JSON.stringify(tokens.token));
    storeInLocalStorage(AUTH_REFRESH_TOKEN_KEY, JSON.stringify(tokens.refresh_token));
};

export const removeTokens = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
};
