import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';

interface EmailFieldProps {
    label: string;
    name: string;
    placeholder?: string;
    isReadOnly?: boolean;
}

const EmailField: FC<EmailFieldProps> = ({ isReadOnly = false, label, name, placeholder = '' }) => (
    <Field name={name}>
        {({ field, form }: FieldProps) => (
            <FormControl isInvalid={!!form.errors[name] && !!form.touched[name]}>
                <FormLabel fontWeight="semibold" htmlFor={name}>{label}</FormLabel>
                <Input
                    id={name}
                    {...field}
                    isReadOnly={isReadOnly}
                    placeholder={placeholder}
                    type='email'
                    variant='outline'
                />
                <FormErrorMessage>{form.errors[name] as string}</FormErrorMessage>
            </FormControl>
        )}
    </Field>
);

export default EmailField;
