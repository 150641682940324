import { Garment, Model } from './api-types';

// Type guard for the product variable if true it's
export default function isModel(data: Model | Garment): data is Model {
    if ((data as Model).model_id) {
        return true;
    }

    return false;
}
