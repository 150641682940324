import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { FC } from 'react';

interface UnclosableBannerProps {
    type: 'error'| 'success' | 'warning' | 'info',
    title: string,
    description?: string
}

/**
 * A Banner without closing button that can be a error, success, warning or info
 * @param param0 UnclosableBannerprops
 */
const UnclosableBanner: FC<UnclosableBannerProps> = ({ type, title, description }) => (
    <Alert status={type}>
        <AlertIcon />
        <AlertTitle mr={2}>{title}</AlertTitle>
        {
            description
            && <AlertDescription>{description}</AlertDescription>
        }
    </Alert>
);

export default UnclosableBanner;
