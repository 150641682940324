import { Filters } from '../components/filters/filtersTypes';
import { FALLBACK_API_URL } from './constants';

export const baseUrl = () => (process.env.BASE_URL || process.env.REACT_APP_BASE_URL || FALLBACK_API_URL);

/**
 * Building the param part of URL for the garment request
 * @param param Garment input infos for the query
 * @returns string of param
 */
export const buildGarmentUrl = (
    query = '', filters: Filters | null = null, page: number, collapse: number | null = null, experienceId: string | null = null,
) => {
    // Can't use URLSearchParam because it's encoding the squared bracket of the array param
    let fullFilterString = '';
    if (filters) {
        Object.keys(filters).forEach((filterKey) => {
            if (Array.isArray(filters[filterKey])) {
                (filters[filterKey] as string[]).forEach((filterValue) => {
                    fullFilterString = fullFilterString.concat(
                        `&filter[${encodeURIComponent(filterKey)}][]=${encodeURIComponent(filterValue)}`,
                    );
                });
            } else {
                fullFilterString = fullFilterString.concat(
                    `&filter[${encodeURIComponent(filterKey)}]=${encodeURIComponent((filters[filterKey] as string))}`,
                );
            }
        });
    }
    const encodedQuery = encodeURIComponent(query);
    const finalQuery = encodedQuery.concat(
        fullFilterString,
        `&page=${page}`,
        collapse ? `&collapse=${collapse}` : '',
        experienceId ? `&experience_id=${experienceId}` : '',
    );

    return '?query='.concat(finalQuery);
};

/**
 * Build the param part of URL for the fetching with ID requests
 * @param ids ids we want to filter on
 * @param filterKey the key of the filter array
 * @returns string of idFilters
 */
export const buildIdFilters = (ids: string[], filterKey: string) => {
    let fullFilterString = '';
    ids.forEach((id, index) => {
        fullFilterString = fullFilterString.concat(`${index !== 0 ? '&' : ''}filter[${filterKey}][]=${id}`);
    });

    return fullFilterString;
};
