import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientConfig, ClientResponse, CustomGroup, Experience, GroupConfig } from '../../../types/api-types';
import { localTokenExist } from '../../../utils/auth-helpers';
import type { RootState } from '../store';

export interface SessionSlice {
    authenticated: boolean,
    client: ClientResponse | null,
    isClientInitialized: boolean, // This is used to know if we finished fetching the clients eventhough there is none
    config: ClientConfig | null,
    experience: Experience | null,
    group: CustomGroup | null
    groupConfig: GroupConfig | null
}

const initialState: SessionSlice = {
    authenticated: localTokenExist(),
    client: null,
    config: null,
    experience: null,
    group: null,
    groupConfig: null,
    isClientInitialized: false,
};

// Slice storing the tokens used in the API calls
export const sessionSlice = createSlice({
    initialState,
    name: 'session',
    reducers: {
        setAuthenticated: (state: SessionSlice, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setClient: (state: SessionSlice, action: PayloadAction<ClientResponse | null>) => {
            state.client = action.payload;
            state.isClientInitialized = true;
        },
        setConfig: (state: SessionSlice, action: PayloadAction<ClientConfig | null>) => {
            state.config = action.payload;
        },
        setExperience: (state: SessionSlice, action: PayloadAction<Experience | null>) => {
            state.experience = action.payload;
        },
        setGroup: (state: SessionSlice, action: PayloadAction<CustomGroup | null>) => {
            state.group = action.payload;
        },
        setGroupConfig: (state: SessionSlice, action: PayloadAction<GroupConfig | null>) => {
            state.groupConfig = action.payload;
        },
    },
});

// ----- Actions -----
export const {
    setAuthenticated,
    setClient,
    setConfig,
    setExperience,
    setGroup,
    setGroupConfig,
} = sessionSlice.actions;

// ----- Selector -----
export const isAuthenticated = (state: RootState) => state.session.authenticated;
export const hasClient = (state: RootState) => state.session.client !== null;
export const getClient = (state: RootState) => state.session.client;
export const isClientInitialized = (state: RootState) => state.session.isClientInitialized;
export const getConfig = (state: RootState) => state.session.config;
export const getGroup = (state: RootState) => state.session.group;
export const getGroupConfig = (state: RootState) => state.session.groupConfig;
export const getExperience = (state: RootState) => state.session.experience;

export default sessionSlice.reducer;
